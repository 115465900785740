@use 'sass:map';
@use '@angular/material' as mat;

@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin general() {
  .topaz-filter {
    border-radius: v.$topaz-border-radius;
    margin: 0.25em;
  }

  .cdk-overlay-pane:has(.topaz-single-filter-overlay) {
    max-height: none !important;
  }

  .filter-name {
    color: v.$ui-gray-1;
    font-weight: 400;

    &-bold {
      font-weight: 700;
    }
  }

  .filter-icon {
    color: v.$ui-gray-1;
  }

  .topaz-single-filter-trigger {
    fa-icon {
      position: relative;
    }

    topaz-capsule {
      margin-right: 0.25em;
    }

    margin: 0.25em;
    .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 0.25em;
    }
  }

  .single-filter-selection-list:focus::before {
    background-color: var(
      --mdc-list-list-item-hover-state-layer-color
    ) !important;
    opacity: var(--mdc-list-list-item-hover-state-layer-opacity) !important;
  }

  .topaz-single-filter-overlay {
    min-width: 180px !important;

    border: none;
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    &.mat-mdc-card {
      margin: 0px !important;
      margin-block: 0px !important;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .topaz-single-filter-list-item {
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .topaz-single-filter-actions {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .topaz-single-filter-search {
      width: 100%;
    }
  }

  .topaz-single-filter-overlay.mat-mdc-card {
    padding: 0rem !important;
  }

  button:not(.mdc-icon-button).topaz-button-small {
    color: v.$ui-gray-1;
    border-color: v.$ui-gray-1;
  }

  .mat-selection-list-width {
    min-width: 12.5rem;
  }

  .button-padding {
    padding: 0 0.5rem;
  }
  .left {
    padding-left: 0.5rem;
  }
  .right {
    padding-right: 0.5rem;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }
}
