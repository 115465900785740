@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/typography' as t;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-primary {
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      color: mat.m2-get-color-from-palette($primary-palette, 600);
      font-weight: 700;
    }
  }

  .mat-mdc-option {
    --mat-option-focus-state-layer-color: var(--topaz-primary-50);
  }

  .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
    background: var(
      --mdc-theme-text-primary-on-background,
      rgba(0, 0, 0, 0.87)
    );
  }

  .mat-mdc-optgroup-label {
    span {
      color: map.get(p.$topaz-neutral, 700) !important;
    }
  }

  .mdc-list-item--selected .mdc-list-item__primary-text {
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  }

  //Auto complete icons
  .mat-mdc-form-field .mat-mdc-select-arrow {
    --mat-select-disabled-arrow-color: map.get(
      p.$topaz-neutral,
      700
    ) !important;
  }

  .mat-mdc-select-panel {
    .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      background-color: map.get(p.$topaz-neutral, 100);
    }
    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      background: mat.m2-get-color-from-palette($primary-palette, 50);
    }
    .mat-mdc-option:active:not(.mdc-list-item--disabled) {
      background: mat.m2-get-color-from-palette($primary-palette, 50);
    }
    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
        .mdc-list-item--disabled
      ) {
      background: mat.m2-get-color-from-palette($primary-palette, 50);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .topaz-select {
    .mat-mdc-optgroup {
      .mat-mdc-optgroup-label {
        @include t.topaz-overline-style($typography-config);
      }
    }
  }
}

@mixin general($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .mat-mdc-optgroup-label {
    span.mdc-list-item__primary-text {
      @include t.topaz-overline-style($typography-config);
    }
  }

  [dir='rtl'] [maticonsuffix] {
    padding-left: 1em;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }

  @include general($theme);
}
