/// mat-option is a common component used in multiple different places

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-mdc-option.mat-mdc-option-active:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: mat.m2-get-color-from-palette($primary-palette, 50);
  }
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background: mat.m2-get-color-from-palette($primary-palette, 50);
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: mat.m2-get-color-from-palette($primary-palette, 50);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-option {
    font-size: mat.m2-font-size($typography-config, 'body-1');
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
