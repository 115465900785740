@use '@angular/material' as mat;
@use 'sass:map';
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-timepicker-wrapper {
    --mat-standard-button-toggle-selected-state-background-color: map.get(
      p.$topaz-blue,
      50
    );
    --mat-standard-button-toggle-disabled-selected-state-background-color: var(
      ----mat-standard-button-toggle-disabled-state-background-color
    );

    .mat-button-toggle-checked:not(.mat-button-toggle-disabled) {
      background-color: map.get(p.$topaz-blue, 50);
    }
  }

  // Error state
  .topaz-timepicker-has-error {
    border-color: transparent;
    outline: 2px solid mat.m2-get-color-from-palette($warn-palette, 800);
  }
}

@mixin typography($theme) {
  $config: mat.m2-get-typography-config($theme);
}

@mixin general($theme) {
  .topaz-timepicker-wrapper {
    min-width: 257px;
    max-width: auto;
    display: flex;
    flex-direction: row;
  }

  .topaz-timepicker-am-pm-button {
    height: 50%;
  }

  .topaz-timepicker-am-pm-group {
    margin-inline-start: 0.625rem;
    height: 3.375rem;
    width: 2.5rem;
    margin-top: 0.25rem;
  }

  .topaz-timepicker-tp-form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  //This is for the custom AM/PM toggle buttons
  .topaz-timepicker-wrapper {
    .mat-button-toggle-label-content {
      font-size: 14px !important;
      line-height: initial !important;
      padding: 0 !important;
      text-align: center !important;
    }
  }

  .topaz-timepicker-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  fa-icon {
    cursor: pointer;

    &.clear-button {
      padding-right: 5px;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-color-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }

  @include general($theme);
}
