@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/color-variables' as c;
@use '../theme/palettes' as p;
@use '../utilities/variables' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .mat-expansion-panel-header-title {
    color: mat.m2-get-color-from-palette($primary-palette, 900) !important;
    .topaz-title {
      color: mat.m2-get-color-from-palette($primary-palette, 900) !important;
    }
  }

  .mat-expansion-panel-header-description {
    color: mat.m2-get-color-from-palette($primary-palette, 900) !important;
  }

  .mat-expansion-panel-header {
    &:hover {
      &:not([aria-disabled='true']) {
        background-color: map.get(p.$topaz-neutral, 200) !important;
      }
    }
  }

  .mat-expansion-indicator {
    color: map.get(p.$topaz-neutral, 700) !important;
  }

  .mat-expansion-panel.mat-expanded {
    border: 2px solid map-get(p.$topaz-complementary, 600);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-expansion-panel-header-title {
    @include mat.m2-typography-level($typography-config, 'body-1');
    font-weight: 700;
  }

  .mat-expansion-panel-body {
    @include mat.m2-typography-level($typography-config, 'body-1');
  }
}

@mixin general() {
  .topaz-wrapper[dir='rtl'] {
    .mat-expansion-panel-header-title {
      fa-icon {
        padding-left: 1.5rem;
        padding-right: 0px !important;
      }
    }
  }

  .mat-expansion-panel-header-title {
    flex-grow: 2 !important;
    fa-icon {
      font-size: 30px;
      padding-right: 1.5rem;
    }
    .topaz-title {
      padding-top: 0.5rem;
    }
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
    margin: 0px !important;
    fa-icon {
      font-size: 30px;
    }
  }

  .mat-expansion-panel-body {
    padding-top: 8px !important;
  }

  .mat-expansion-panel {
    border-radius: 0px !important;
  }

  .mat-expansion-panel-header {
    min-height: 78px;
    height: auto;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: auto;
  }

  .mat-content.mat-content-hide-toggle {
    margin: 0px !important;
  }

  @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
    .mat-expansion-panel-header {
      padding: 0px 16px !important;
    }

    .mat-expansion-panel-header-title {
      fa-icon {
        padding-right: 1rem;
      }
    }

    .topaz-wrapper[dir='rtl'] {
      .mat-expansion-panel-header-title {
        fa-icon {
          padding-left: 1rem;
        }
      }
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
