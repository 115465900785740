@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/outline' as o;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-mdc-icon-button:not([disabled='true']) {
    .svg-inline--fa {
      color: map.get(p.$topaz-neutral, 700);
    }
  }

  .tool-tip-icon {
    color: map.get(p.$topaz-neutral, 700);
    &:focus {
      @include o.topaz-focus();
      border-radius: 60%;
      padding: 0 0.25em;
    }
  }

  // dark theme for fa-icons
  .topaz-icon-dark {
    &:focus-visible {
      @include o.topaz-focus-dark-anchor();
    }
    .svg-inline--fa {
      color: map.get(p.$topaz-neutral, 50) !important;
    }
    &:hover {
      background-color: transparent;
      outline: none;
      .mat-mdc-button-persistent-ripple:before {
        display: none;
      }
      .svg-inline--fa {
        color: map.get($primary-palette, 100) !important;
      }
    }
  }
}

@mixin typography($theme) {
  .topaz-icon-dark {
    .svg-inline--fa {
      height: 1rem;
      width: 1.5rem;
    }
  }
}

@mixin general() {
  .mat-mdc-icon-button {
    .svg-inline--fa {
      vertical-align: -0.11em;

      // firefox specific fix
      @-moz-document url-prefix() {
        vertical-align: -0.04em;
      }
    }
  }
  // dark theme for fa-icons
  .topaz-icon-dark {
    padding: 0.5rem;
    border-radius: 50%;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
