@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../variables-imports' as v;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $alert-palette: map.get($theme, 'alert');

  .topaz-capsule {
    background: var(
      --topaz-capsule-background-color,
      map.get(p.$topaz-neutral, 300)
    );
    color: var(--topaz-capsule-text-color, map.get(p.$topaz-neutral, 800));

    &.primary {
      background: mat.m2-get-color-from-palette($primary-palette, 50);
      color: mat.m2-get-color-from-palette($primary-palette, 700);
    }

    &.accent {
      background: mat.m2-get-color-from-palette($accent-palette, 50);
      color: mat.m2-get-color-from-palette($accent-palette, 900);
    }

    &.warn {
      $bg-color: mat.m2-get-color-from-palette($warn-palette, 50);
      background: $bg-color;
      color: mat.m2-get-color-from-palette($warn-palette, 900);
    }

    @if $alert-palette != null {
      &.alert {
        background: mat.m2-get-color-from-palette($alert-palette, 100);
        color: mat.m2-get-color-from-palette($alert-palette, 900);
      }
    }

    &.topaz-capsule-color-dark {
      background: map.get(p.$topaz-neutral, 700);
      color: map.get(p.$topaz-neutral, 50);

      &.primary {
        background: mat.m2-get-color-from-palette($primary-palette, 600);
        color: mat.m2-get-color-from-palette($primary-palette, '600-contrast');
      }

      &.accent {
        background: mat.m2-get-color-from-palette($accent-palette, 600);
        color: mat.m2-get-color-from-palette($accent-palette, '600-contrast');
      }

      &.warn {
        background: mat.m2-get-color-from-palette($warn-palette, 900);
        color: mat.m2-get-color-from-palette($warn-palette, '900-contrast');
      }

      @if $alert-palette != null {
        &.alert {
          background: mat.m2-get-color-from-palette($alert-palette, 600);
          color: mat.m2-get-color-from-palette($alert-palette, '600-contrast');
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .topaz-capsule {
    font-size: mat.m2-font-size($typography-config, 'body-2');

    line-height: 1.2;
    font-weight: 700;

    &.topaz-capsule-font-small {
      font-size: mat.m2-font-size($typography-config, 'caption');
      line-height: 16px;
      padding: 0.25em 0.75em;
    }

    &.topaz-capsule-font-weight {
      &-light {
        font-weight: 400;
      }

      &-normal {
        font-weight: 700;
      }
    }
  }
}

@mixin common() {
  .topaz-capsule {
    // The 9999px is a css hack to make a pill shape
    border-radius: 9999px;
    padding: 0.3em 0.75em;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    min-width: 5em;
    line-height: 19px;

    fa-icon {
      margin-right: 8px;
    }

    &-full-width {
      width: 200px;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  @include typography($theme);
  @include common();
}
