@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  // Matstepper header container to gray background
  .mat-horizontal-stepper-header-container {
    background-color: map.get(p.$topaz-neutral, 200);
  }

  .mat-step-header {
    // Active state icon and labels
    .mat-step-icon-selected.mat-step-icon {
      color: mat.m2-get-color-from-palette($primary-palette, '600-contrast');
      background-color: mat.m2-get-color-from-palette($primary-palette, 600);
    }

    .mat-step-label.mat-step-label-active.mat-step-label-selected
      .mat-step-text-label {
      color: mat.m2-get-color-from-palette($primary-palette, 600);
    }

    // Default state icon and labels
    .mat-step-icon {
      color: map.get($primary-palette, 600);
      background-color: map.get(p.$topaz-neutral, 50);
      border: 1px solid map.get($primary-palette, 600);
    }

    .mat-step-label:not(.mat-step-label-error) {
      color: map.get(p.$topaz-neutral, 700);
      // background-color: map.get(p.$topaz-neutral, 50);
    }

    // Completed successfully for icon
    .mat-step-icon-state-done:not(.mat-step-label-error) {
      color: map.get(p.$topaz-neutral, 50);
      background-color: mat.m2-get-color-from-palette($accent-palette, 700);
    }

    // error state for icon
    .mat-step-icon-state-error {
      color: map.get(p.$topaz-neutral, 50);
      background-color: mat.m2-get-color-from-palette($warn-palette, 800);
      border: 1px solid mat.m2-get-color-from-palette($warn-palette, 800);
      // default icon size is 24px, hence changing to 12px to match with design
      & .mat-icon {
        font-size: 12px;
        height: 12px;
        width: 12px;
      }
    }

    // Hover state
    &:hover {
      border-radius: 100px !important;
      border: 1px solid map.get($primary-palette, 600);
      background-color: map.get(p.$topaz-neutral, 50) !important;
    }

    // Focus state
    &.cdk-focused {
      border-radius: 100px !important;
      border: 2px solid map.get($primary-palette, 600);
      background-color: map.get(p.$topaz-neutral, 200);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-step-text-label {
    @include mat.m2-typography-level(
      mat.m2-get-typography-config($theme),
      'body-1'
    );
  }
  .mat-step-label-selected .mat-step-text-label {
    font-weight: 700;
  }
}

@mixin general() {
  .mat-horizontal-stepper-header-container {
    margin: 1.5rem 0;
  }

  .mat-step-header {
    .mat-focus-indicator {
      display: none;
    }
  }

  .mat-stepper-horizontal-line {
    margin: 0 !important;
  }

  .mat-horizontal-stepper-header:hover,
  .mat-horizontal-stepper-header:focus {
    height: 56px;
  }

  .mat-horizontal-stepper-header-container {
    padding: 0 1rem;
  }

  .mat-vertical-stepper-header {
    padding: 1rem;
    width: fit-content;
  }

  .mat-vertical-content-container.mat-stepper-vertical-line {
    margin-left: 1.75rem;
    padding-top: 1.5rem;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
