@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../variables-imports' as v;
@use '../theme/palettes' as p;

@mixin default-elevation() {
  @include mat.elevation(2);
}

@mixin glowout-animation($color, $theme-name) {
  animation: glowout-#{$theme-name} 1500ms ease-out;

  @keyframes glowout-#{$theme-name} {
    0% {
      @include mat.elevation(12, $color);
    }
    100% {
      @include default-elevation();
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $alert-palette: map.get($theme, 'alert');

  $color-primary: mat.m2-get-color-from-palette($primary-palette, 300);
  $color-accent: mat.m2-get-color-from-palette($accent-palette, 700);
  $color-warn: mat.m2-get-color-from-palette($warn-palette, 800);

  $color-alert: null;

  @if $alert-palette != null {
    $color-alert: mat.m2-get-color-from-palette($alert-palette, 700);
  }

  .topaz-banner {
    border-left: 3px solid $color-primary;
    background-color: v.$ui-white;
    &.topaz-banner-glow {
      @include glowout-animation($color-primary, 'default');
    }

    &-subcontent {
      color: map.get(p.$topaz-neutral, 700);
    }

    &.topaz-baner-color-primary {
      border-left: 3px solid $color-primary;
      &.topaz-banner-glow {
        @include glowout-animation($color-primary, 'primary');
      }
    }

    &.topaz-banner-color-accent {
      border-left: 3px solid $color-accent;
      &.topaz-banner-glow {
        @include glowout-animation($color-accent, 'accent');
      }
    }

    &.topaz-banner-color-warn {
      border-left: 3px solid $color-warn;
      &.topaz-banner-glow {
        @include glowout-animation($color-warn, 'warn');
      }
    }

    @if $color-alert != null {
      &.topaz-banner-color-alert {
        border-left: 3px solid $color-alert;
        &.topaz-banner-glow {
          @include glowout-animation($color-alert, 'alert');
        }
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin general() {
  .topaz-banner {
    margin-block: 0.75rem;
    padding: 1rem;
    border-radius: v.$topaz-border-radius;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'icon content .'
      '.    action  .';

    &.topaz-banner-has-close {
      grid-template-columns: auto 1fr auto auto;
      grid-template-areas:
        'icon content . close'
        '.    action  . .';
    }

    .topaz-banner-section-icon > .topaz-banner-icon {
      height: 48px;
    }

    column-gap: 1.5rem;

    @include default-elevation();

    @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
      grid-template-areas: 'icon content action';

      &.topaz-banner-has-close {
        grid-template-areas: 'icon content action close';
      }

      padding-block: 1rem;
      padding-inline: 1.5rem;
    }

    .t-warning {
      background-color: red;
    }

    .topaz-banner-section-icon {
      grid-area: icon;
      img {
        height: 48px;
        width: 48px;

        &.topaz-banner-icon-circle {
          border-radius: 100%;
        }
      }
    }

    .topaz-banner-section-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-area: content;
      .topaz-banner-content {
        display: flex;
        align-items: center;
        gap: 0.5em;
      }

      &.topaz-banner-close-disabled {
        gap: 0;
      }
    }

    .topaz-banner-section-action {
      grid-area: action;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        margin-inline-start: -1em;
      }

      @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
        &.topaz-banner-action-has-content {
          border-inline-end: 1px solid v.$topaz-border-color;
          padding-inline-end: 1rem;
        }
      }
    }

    .topaz-banner-section-end {
      display: flex;
      grid-area: close;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  @include general();
}
