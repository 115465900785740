@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    border-color: mat.m2-get-color-from-palette(
      $primary-palette,
      600
    ) !important;
    background-color: mat.m2-get-color-from-palette(
      $primary-palette,
      600
    ) !important;
  }

  .is-invalid.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background {
    border-color: map.get(p.$topaz-red, 800) !important;
    border-width: 2px;
  }

  .is-invalid.mat-mdc-form-field-error {
    font-size: 75%;
    display: block;
    position: absolute;
    width: 100%;
  }

  //Focus
  .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
    @include o.topaz-focus();
    //Transparent background
    background-color: rgba(0, 0, 0, 0) !important;
  }
  //Hover
  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:not([disabled])
    ~ .mdc-checkbox__ripple {
    @include o.topaz-hover-alpha();
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 9px !important;
    left: 9px !important;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    border-width: 1.5px;

    // Decrease Icon sizes
    & > svg,
    & > .mdc-checkbox__mixedmark {
      transform: scale(0.7) !important;
    }
  }
}

@mixin general() {
  .is-invalid.mat-mdc-form-field-error {
    padding-bottom: 0.75rem;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
