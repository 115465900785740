@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../variables-imports' as v;
@use '../theme/palettes' as p;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  .mat-button-toggle-group,
  .mat-button-toggle {
    --mat-standard-button-toggle-selected-state-background-color: var(
      --topaz-primary-100
    );
    --mat-standard-button-toggle-selected-state-text-color: var(
      --topaz-primary-600
    );
    --mat-standard-button-toggle-divider-color: var(--topaz-neutral-700);
    --mat-standard-button-toggle-hover-state-layer-opacity: 0;
    --mat-standard-button-toggle-focus-state-layer-opacity: 0;
    color: map.get(p.$topaz-neutral, 700);
    border-color: map.get(p.$topaz-neutral, 700);
    display: flex;
    flex-direction: row;
  }

  mat-button-toggle {
    box-shadow: var(--topaz-box-shadow, inherit);
    &:hover,
    &:focus-visible {
      --mat-standard-button-toggle-background-color: var(--topaz-primary-50);
      --mat-standard-button-toggle-hover-state-background-color: red;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .mat-button-toggle {
    @include mat.m2-typography-level($typography-config, 'body-2');
    &.mat-button-toggle-checked:not(.mat-button-toggle-disabled) {
      font-weight: mat.m2-font-weight($typography-config, 'button');
    }
  }
}

@mixin general() {
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
