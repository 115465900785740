@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  // Normal state
  .topaz-search {
    border-width: 1px;
    border-style: solid;
    border-color: map.get(p.$topaz-neutral, 700);
    background-color: map.get(p.$topaz-neutral, 50);

    &:hover {
      border-color: transparent;
      outline: 3px solid map.get(p.$topaz-neutral, 700);
      outline-offset: -1px;
    }

    .ng-fa-icon {
      color: map.get(p.$topaz-neutral, 700);
    }

    input {
      background-color: transparent;
      border: none;
      outline: none;
      color: map.get(p.$topaz-neutral, 900);
    }

    hr {
      border-width: 1px;
      border-style: solid;
      border-color: map.get(p.$topaz-neutral, 400);
    }

    .mat-mdc-icon-button {
      svg.fa-arrow-right {
        color: mat.m2-get-color-from-palette($primary-palette, 600);
      }
    }
  }

  // Focused state
  .topaz-search-focused {
    .topaz-search {
      border-color: transparent;
      outline: 3px solid mat.m2-get-color-from-palette($primary-palette, 600);
      outline-offset: -1px;
    }
  }

  // Error state
  .topaz-search-has-error {
    .topaz-search {
      border-color: transparent;
      outline: 2px solid mat.m2-get-color-from-palette($warn-palette, 800);
    }
  }

  // Disabled state
  .topaz-search-disabled {
    .topaz-search {
      background-color: transparent;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  // Normal state
  .topaz-search {
    input {
      @include mat.m2-typography-level($typography-config, 'body-1');
    }
  }

  // Error state
  .topaz-search-has-error .mat-mdc-form-field-error {
    // using 75% is what angular material does for errors
    font-size: 75%;
    margin-block-start: 0.5em;
  }
}

@mixin general() {
  .topaz-search {
    display: flex;
    width: 100%;
    // using px here because size should be same as icon button size, which is always 40px
    height: 40px;
    box-sizing: border-box;
    border-radius: v.$topaz-border-radius;
    align-items: center;
    flex-direction: row;

    input {
      width: 100%;
    }

    &-large {
      @extend .topaz-search;
      height: 56px;

      .divider-large {
        height: 44px;
        margin-right: 3px;
      }
    }

    .divider-small {
      height: 30px;
    }

    .topaz-search-icon {
      svg {
        min-width: 40px;
      }
    }

    .mat-mdc-icon-button {
      svg.fa-arrow-right {
        transform: scale(0.9);
      }
    }
  }

  .topaz-search-button-large {
    margin-right: 3px;

    .mat-mdc-icon-button {
      transform: scale(1.2);
    }
  }

  .topaz-search-disabled {
    .topaz-search {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .topaz-search-has-error .mat-mdc-form-field-error {
    padding-inline-start: 1em;
    color: map.get(p.$topaz-red, 800);
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }

  @include general();
}
