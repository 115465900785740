@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-loader {
    &-bg {
      $color: map.get(p.$topaz-neutral, 900);
      background-color: color.scale($color, $alpha: -25%);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .topaz-loader {
    &-content {
      p {
        color: map.get(p.$topaz-neutral, 900);
        font-size: 12px;
        font-weight: 700;
        &:nth-of-type(2) {
          color: map.get(p.$topaz-neutral, 700);
        }
      }
    }
  }
}

@mixin general() {
  .topaz-loader {
    &-bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 9001;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 373px;
      padding: 2rem 0rem;
      background: v.$ui-white;
      border-radius: 10px;
      .mat-mdc-progress-spinner {
        padding-bottom: 20px;
      }
    }
  }
  * {
    &:focus-visible {
      outline: none;
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
