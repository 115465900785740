@use 'sass:map';
@use '@angular/material' as mat;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-badge,
  .mat-badge-primary {
    .mat-badge-content {
      @include _badgeColor($primary-palette, 600);
    }
  }

  .mat-badge-warn {
    .mat-badge-content {
      @include _badgeColor($warn-palette, 900);
    }
  }

  .mat-badge-accent {
    .mat-badge-content {
      @include _badgeColor($accent-palette, 900);
    }
  }

  .inverse.mat-badge-primary {
    .mat-badge-content {
      @include _badgeColor($primary-palette, 100);
    }
  }

  .inverse.mat-badge-warn {
    .mat-badge-content {
      @include _badgeColor($warn-palette, 900);
    }
  }

  .inverse.mat-badge-accent {
    .mat-badge-content {
      @include _badgeColor($accent-palette, 900);
    }
  }
}

@mixin _badgeColor($palette, $shade) {
  background-color: mat.m2-get-color-from-palette($palette, $shade);
  color: mat.m2-get-color-from-palette($palette, '#{$shade}-contrast');
  border: 2px solid
    mat.m2-get-color-from-palette($palette, '#{$shade}-contrast');
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-badge-large {
    .mat-badge-content {
      font-family: mat.m2-font-family($typography-config, 'caption');
      font-size: mat.m2-font-size($typography-config, 'caption') !important;
    }
  }

  .mat-badge-medium {
    .mat-badge-content {
      font-family: mat.m2-font-family($typography-config, 'caption');
      font-size: 0.625rem !important;
    }
  }
}

@mixin general() {
  .mat-badge-small {
    .mat-badge-content {
      color: transparent !important;
    }
  }

  .mat-badge-medium {
    .mat-badge-content {
      width: 1.625rem !important;
      height: 1.625rem !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mat-badge-large {
    .mat-badge-content {
      width: 2rem !important;
      height: 2rem !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
