@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/variables' as v;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .upload-container {
    &:hover {
      border: 1.5px dashed mat.m2-get-color-from-palette($primary-palette, 900);
      //color: mat.m2-get-color-from-palette($primary-palette, 300);
      //box-shadow: 0px 0px 2px 4px map-get(p.$topaz-complementary, 900);
      .drag-drop-cloud-up-icon {
        color: mat.m2-get-color-from-palette($primary-palette, 900);
      }
    }

    &:focus {
      background: rgba(255, 255, 255, 0);
      @include o.topaz-focus();
    }
  }

  .file-upload-link {
    &:hover {
      color: mat.m2-get-color-from-palette($primary-palette, 900);
    }

    &:focus {
      background: map.get(p.$topaz-neutral, 100);
      @include o.topaz-focus();
    }

    &:active {
      color: mat.m2-get-color-from-palette($primary-palette, 100);
    }
  }

  .drag-drop-cloud-up-icon {
    color: map.get(p.$topaz-neutral, 500);
    &:hover {
      color: mat.m2-get-color-from-palette($primary-palette, 900);
    }

    &:focus {
      color: map.get(p.$topaz-neutral, 500);
    }

    &:active {
      color: mat.m2-get-color-from-palette($primary-palette, 900);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .label-text {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1.25px;
    color: map.get(p.$topaz-neutral, 700);
    padding-bottom: 0.25rem;
  }

  .error-text {
    margin: 0.5rem;
    color: map.get(p.$topaz-neutral, 700);
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 114.286% */
    color: map.get(p.$topaz-red, 800);

    ul {
      padding-inline-start: 1rem !important;
    }
  }

  .hint-text {
    color: map.get(p.$topaz-neutral, 700);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  input {
    @include mat.m2-typography-level($typography-config, 'body-1');
    color: map.get(p.$topaz-neutral, 700);

    &::placeholder {
      color: map.get(p.$topaz-neutral, 700) !important;
      @include mat.m2-typography-level($typography-config, 'body-1');
    }
  }
}

@mixin general() {
  @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
    section .mat-mdc-card {
      padding: 0.25rem !important;
    }
  }
  .upload-container {
    width: 33.313rem;
    padding: 2rem;
    margin-top: 1.25rem;
    text-align: center;
    border-radius: 0.5rem;
    border: 1.5px dashed map.get(p.$topaz-neutral, 400);

    @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
      width: 19.25rem;
    }
  }

  .file-upload-link {
    color: #007394;
    text-decoration: underline;
    cursor: pointer;
  }

  .uploaded-files {
    width: 33.313rem;
    display: flex;

    @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
      width: 21.438rem;
    }

    mat-list-item {
      width: 37.5rem;
      //padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border: 1px solid #ccc; /* Add border line to list items */

      @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
        width: 23rem;
      }
    }

    mat-list-item span {
      flex-grow: 1;
      text-align: left; /* Align filenames to the left */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      position: absolute;
      right: 0;
      bottom: 2px;
      color: red;
      margin-left: 0.5rem; /* Align delete icon to the right */
      flex-shrink: 0; /* Prevent the button from shrinking */
    }
  }

  .drag-drop-cloud-up-icon {
    text-align: center;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Font Awesome 6 Pro';
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }

  @include general();
}
