@use 'sass:color';
@use '../theme/palettes' as p;

/*
  TEXT COLOR
  Text colors have been selected to ensure optimal readability on the screen,
  using WCAG 2.0 AA contrast guidelines. Most text is on a white or light gray
  background, but contrast should be verified when this is not the case.
*/
$text-black: #000000;
$text-white: #ffffff;
$text-gray: #000000b5;
$text-white-2: #ffffffd2;

/*
  UI COLOR
  UI colors shoulld be used as fillls for non-text UI elements such as
  backgrounsd, bars, and dividers. Use the component libary as an
  additional guide for UI color.
*/
$ui-black: #000000;
$ink-black: $ui-black;

$ui-gray-1: #666666;
$graphite-grey: $ui-gray-1;

$ui-gray-2: #c3c3c4;

$ui-gray-3: #dfe1e1;
$mist-grey: $ui-gray-3;

$background-gray: #f5f6f8;
$background-teal: #e0eff4;
$ui-white: #ffffff;
$background-transparent: rgba(0, 0, 0, 0.75);

/*
  BRAND COLORS
*/
$topaz-color-pearson-blue: #0074a3; // <- this can only be used for the logo
$topaz-color-chalk-white: #ffffff;
$topaz-color-bright-orange: #ffbb1c;
$topaz-color-lime-green: #d2db0e;
$topaz-color-sky-blue: #94e7ea;
$topaz-color-coral-pink: #ff757a;
$topaz-color-fresh-green: #84bd00;
$topaz-color-marine-turquoise: #12b2a6;

$topaz-colors-brand: (
  'pearson-blue': #0074a3,
  // <- this can only be used for the logo
  'chalk-white': #ffffff,
  'bright-orange': #ffbb1c,
  'lime-green': #d2db0e,
  'sky-blue': #94e7ea,
  'coral-pink': #ff757a,
  'fresh-green': #84bd00,
  'marine-turquoise': #12b2a6
);

/// use map-deep-get function from functions scss file
$topaz-colors-light: (
  'bright-orange': p.$topaz-bright-orange,
  'lime-green': p.$topaz-lime-green,
  'sky-blue': p.$topaz-sky-blue
);

/// use map-deep-get function from functions scss file
$topaz-colors-mid: (
  'coral-pink': p.$topaz-coral-pink,
  'fresh-green': p.$topaz-fresh-green,
  'marine-turquoise': p.$topaz-marine-turquoise
);

/// use map-deep-get function from functions scss file
$topaz-colors-dark: (
  'royal-purple': p.$topaz-royal-purple,
  'grass-green': p.$topaz-grass-green,
  'midnight-blue': p.$topaz-midnight-blue
);
