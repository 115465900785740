@use 'sass:map';
@use '@angular/material' as mat;
@use '../variables-imports' as v;
@use '../theme/palettes' as p;
@use '../theme/typography';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .menu-title-label {
    color: map.get(p.$topaz-neutral, 700);
  }

  .mat-mdc-menu-content {
    min-width: 180px !important;
  }

  .mat-mdc-menu-item-highlighted:not([disabled]) {
    background: mat.m2-get-color-from-palette($primary-palette, 50);
  }
  .mat-mdc-menu-item {
    &:hover:not([disabled]),
    &:active:not([disabled]) {
      background: mat.m2-get-color-from-palette(
        $primary-palette,
        50
      ) !important;
    }

    //Menu icons are lighter than the text.
    &:not(.topaz-warn) {
      fa-icon {
        color: map.get(p.$topaz-neutral, 700);
      }
      .mdc-list-item__primary-text {
        color: map.get(p.$topaz-neutral, 900);
      }
    }

    //Convenience css for styling common menus.
    &.topaz-warn {
      color: mat.m2-get-color-from-palette($warn-palette, 800);
      fa-icon,
      .mdc-list-item__primary-text {
        color: mat.m2-get-color-from-palette($warn-palette, 800);
      }
    }
    &.topaz-primary {
      fa-icon,
      .mdc-list-item__primary-text {
        color: mat.m2-get-color-from-palette($primary-palette, 700);
      }
    }
    &.topaz-accent {
      fa-icon,
      .mdc-list-item__primary-text {
        color: mat.m2-get-color-from-palette($accent-palette, 800);
      }
    }

    &.topaz-icon-menu-item {
      padding: 0;
      span {
        margin: 0 1em;
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .menu-title-label {
    @include typography.topaz-overline-style($typography-config);
  }
}

@mixin general() {
  .mat-mdc-menu-panel {
    max-height: 440px !important;
  }

  .menu-title-label {
    display: inline-block;
    padding: 0.5rem 1rem;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
