@use '@pearsonvue/topaz-angular-ui' as topaz;

@include topaz.core-theme();
@include topaz.one-column-layout();
@include topaz.margin-utils();
@include topaz.padding-utils();
@import 'intl-tel-input/build/css/intlTelInput.css';

* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}
h1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}
h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22.38px;
  line-height: 31px;
}