@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  a:not([class*='mat-']).topaz-nav-link {
    color: map.get(p.$topaz-neutral, 50);
    &:hover {
      color: mat.m2-get-color-from-palette($primary-palette, 300);
    }

    &:focus {
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 0px 2px 4px map-get(p.$topaz-complementary, 800);
    }

    &:active {
      color: mat.m2-get-color-from-palette($primary-palette, 100);
    }
    &--disabled {
      color: map.get(p.$topaz-neutral, 500);
    }
    &--active {
      color: mat.m2-get-color-from-palette($primary-palette, 100);
    }
    &--focus {
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 0px 2px 4px map-get(p.$topaz-complementary, 800);
    }
    &--hover {
      color: mat.m2-get-color-from-palette($primary-palette, 300);
    }
  }

  a.topaz-nav-link-mobile {
    color: map.get(p.$topaz-neutral, 900);
    &:hover,
    &--hover {
      color: map.get(p.$topaz-neutral, 700) !important;
    }

    &:active,
    &--active {
      color: mat.m2-get-color-from-palette($primary-palette, 700) !important;
    }

    &:focus,
    &--focus {
      box-shadow: 0px 0px 2px 3px map-get(p.$topaz-complementary, 800);
    }
  }

  a:focus-visible {
    outline: none;
    @include o.topaz-focus();
  }

  a:not([class*='mat-']) {
    color: mat.m2-get-color-from-palette($primary-palette, 600);

    // NOTE: :visited only works for external links, not angular routerLinks
    &:visited {
      color: map.get(p.$topaz-royal-purple, 500);
    }

    &:hover {
      color: mat.m2-get-color-from-palette($primary-palette, 900);
    }

    &.topaz-link-dark {
      color: mat.m2-get-color-from-palette($primary-palette, 100);

      &:visited {
        color: map.get(p.$topaz-royal-purple, 200);
      }

      &:hover {
        color: mat.m2-get-color-from-palette($primary-palette, 50);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  a:not([class*='mat-']) {
    font-family: mat.m2-font-family($typography-config);
    // font-weight: 600;
    &.topaz-link-small {
      @include mat.m2-typography-level($typography-config, 'body-2');
    }

    &.topaz-link-bold {
      font-weight: 700;
    }
  }
}

@mixin general() {
  a:not([class*='mat-']).topaz-nav-link {
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: 0.875rem !important;
    max-width: 11.25em;
    text-align: center;
    &:focus {
      border-radius: 4px;
    }
    &--disabled {
      pointer-events: none;
      overflow: hidden;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
    &--active {
      overflow: hidden;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
    }
    &--focus {
      border-radius: 4px;
    }
  }

  a:not([class*='mat-']) {
    text-decoration: underline;
    cursor: pointer;
  }

  a.topaz-nav-link-mobile {
    text-decoration: none !important;
    &:hover,
    &--hover {
      cursor: pointer;
    }

    &:focus,
    &--focus {
      border-radius: 0.25em;
      padding: 0.25em 0.5em;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
  @include general();
}
