@use 'sass:map';
@use '@angular/material' as mat;

$shades: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400,
  A7000;

@function isAShade($shade) {
  @if $shade==A100 or $shade==A200 or $shade==A400 or $shade==A7000 {
    @return true;
  } @else {
    @return false;
  }
}

@mixin custom-colors($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .color-bg-primary {
    background-color: mat.m2-get-color-from-palette($primary-palette);
    color: mat.m2-get-color-from-palette($primary-palette, 'default-contrast');

    @each $shade in $shades {
      &-#{$shade} {
        background-color: mat.m2-get-color-from-palette(
          $primary-palette,
          $shade
        );
        color: mat.m2-get-color-from-palette(
          $primary-palette,
          '#{$shade}-contrast'
        );
      }
    }
  }

  .color-bg-accent {
    background-color: mat.m2-get-color-from-palette($accent-palette);
    color: mat.m2-get-color-from-palette($accent-palette, 'default-contrast');

    @each $shade in $shades {
      &-#{$shade} {
        background-color: mat.m2-get-color-from-palette(
          $accent-palette,
          $shade
        );
        color: mat.m2-get-color-from-palette(
          $accent-palette,
          '#{$shade}-contrast'
        );
      }
    }
  }

  .color-bg-warn {
    background-color: mat.m2-get-color-from-palette($warn-palette);
    color: mat.m2-get-color-from-palette($warn-palette, 'default-contrast');

    @each $shade in $shades {
      &-#{$shade} {
        background-color: mat.m2-get-color-from-palette($warn-palette, $shade);
        color: mat.m2-get-color-from-palette(
          $warn-palette,
          '#{$shade}-contrast'
        );
      }
    }
  }
}
