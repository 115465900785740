@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/color-variables' as c;
@use '../theme/palettes' as p;
@use '../utilities/variables';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  mat-form-field {
    --mdc-outlined-text-field-label-text-color: map.get(p.$topaz-neutral, 700);
  }

  .mat-form-field-disabled {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: map.get(p.$topaz-neutral, 400);
    }
    .mdc-floating-label,
    .mat-mdc-form-field-hint,
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-text-prefix {
      color: map.get(p.$topaz-neutral, 400) !important;
    }
  }

  .mdc-text-field--invalid {
    border-width: 2px !important;
  }

  .mdc-text-field--disabled {
    border: 1px solid map.get(p.$topaz-neutral, 400);
    border-radius: 6px !important;
    fa-icon {
      color: map.get(p.$topaz-neutral, 400) !important;
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    --mdc-outlined-text-field-hover-outline-color: var(--topaz-neutral-700);
    --mdc-outlined-text-field-focus-outline-width: 3px;
    &:hover {
      --mdc-outlined-text-field-outline-width: 3px;
    }
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-notched-outline {
    // Unselected should be neutral regardless of theme
    &:not(.mdc-text-field--focused):not(.mdc-text-field--invalid) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        color: map.get(p.$topaz-neutral, 700) !important;
      }

      &:hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: map.get(p.$topaz-blue, 600);
          border-width: 3px;
        }
        .mdc-notched-outline__notch {
          border-left: none;
        }
      }
    }

    // Focus/Active
    &.mdc-text-field--focused:not(.mdc-text-field--invalid) {
      .mdc-floating-label {
        color: mat.m2-get-color-from-palette($primary-palette, 600);
      }
    }

    //Focus border width
    &.mdc-text-field--focused {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-width: 3px;
      }
    }

    &.mdc-text-field--focused.mdc-text-field--invalid {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-width: 2px;
      }
    }
  }
  // Apply default neutral coloring to icons
  .mat-mdc-form-field fa-icon {
    color: map.get(p.$topaz-neutral, 700);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  // by default cdk is setting the default font size as 14,
  // so we need to update using our theme to make the font size to 16.
  .mat-mdc-radio-button .mdc-form-field {
    @include mat.m2-typography-level($typography-config, 'body-1');
  }
}

@mixin general() {
  // For input fields with icon suffix we need less spacing. e.g. matSelect
  @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'mobile')) {
    .mat-mdc-form-field-has-icon-suffix
      .mdc-text-field--outlined
      .mdc-notched-outline
      .mdc-notched-outline__notch {
      max-width: calc(100% - 60px) !important;
    }
  }

  .mat-form-field-appearance-outline {
    .mdc-text-field .mdc-floating-label {
      top: 1.625em;
    }
  }

  .mat-mdc-form-field-required-marker.mdc-floating-label--required {
    padding-left: 0.25rem;
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      flex-grow: 0;
    }
    &.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
      padding-left: 1rem;
      --mat-mdc-form-field-label-offset-x: 0px;
      .mat-mdc-form-field-icon-prefix fa-icon {
        padding-right: 0.5rem;
      }
    }

    &.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
      padding-right: 1rem;
    }
  }

  mat-form-field {
    width: 100%;
    margin-top: 0.25rem;

    .mat-mdc-form-field-subscript-wrapper {
      &:before {
        content: none;
      }
      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        position: relative;
      }
      .mat-mdc-form-field-error,
      .mat-mdc-form-field-hint {
        min-height: 1.5rem;
        padding-bottom: 0.75rem;
      }
      .mat-mdc-form-field-hint {
        color: map.get(p.$topaz-neutral, 700);
      }
      min-height: 1.5rem;
    }
  }
  .mat-mdc-form-field-infix {
    display: flex;
  }
}

@mixin theme($theme) {
  @include general();
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
