@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/themes' as t;
@use '../variables-imports' as v;
@use '../theme/palettes' as p;
@use 'sass:color';
@use '../theme/typography';
@use '../utilities/outline' as o;

$mobile-breakpoint-min: map.get(v.$topaz-breakpoints-min, mobile);
$mobile-breakpoint-max: map.get(v.$topaz-breakpoints-max, mobile);

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  table.topaz-table {
    background-color: v.$ui-white;
    border-radius: v.$topaz-border-radius;
    border-collapse: collapse;
    border-style: hidden;

    .mat-sort-header-arrow {
      color: var(--topaz-table-sort-header-color, --topaz-neutral-700);
    }

    tbody {
      tr {
        box-sizing: border-box;

        &:first-child {
          border-block-end: 1px solid map.get(p.$topaz-neutral, 300);

          @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
            border-block-start: none;

            &:last-child {
              border-block-end: none;
            }
          }
        }

        border-block-end: 1px solid map.get(p.$topaz-neutral, 300);

        &:hover {
          background-color: mat.m2-get-color-from-palette($primary-palette, 50);
          filter: drop-shadow(0 1px map.get(p.$topaz-neutral, 400))
            drop-shadow(0 -1px map.get(p.$topaz-neutral, 400));
        }

        td::before {
          color: map.get(p.$topaz-neutral, 700);
        }

        &.selected {
          background-color: mat.m2-get-color-from-palette(
            $primary-palette,
            100
          );
        }
      }

      @media screen and (max-width: $mobile-breakpoint-max) {
        td {
          border: none;
        }
      }
    }

    thead {
      tr {
        border-bottom: 1px solid map.get(p.$topaz-neutral, 300);
      }

      @media screen and (max-width: $mobile-breakpoint-max) {
        th.topaz-batch-options {
          border-bottom: 1px solid map.get(p.$topaz-neutral, 300);

          .mat-badge {
            height: 6px;
          }
        }
      }

      th {
        color: map.get(p.$topaz-neutral, 700);
      }
    }
  }

  .topaz-table + .mat-mdc-paginator {
    border-top: 1px solid map.get(p.$topaz-neutral, 300);
    color: map.get(p.$topaz-neutral, 900);

    button.mat-mdc-icon-button:not([disabled='true']) {
      color: map.get(p.$topaz-neutral, 700);
    }

    .mat-focused {
      @include o.topaz-focus();
    }

    .mat-mdc-paginator-page-size-select {
      width: 3.875rem !important;
    }

    .mat-mdc-paginator-container {
      .mat-mdc-paginator-page-size {
        .mat-mdc-text-field-wrapper {
          background-color: v.$ui-white;
        }

        .mat-mdc-form-field-focus-overlay {
          background-color: v.$ui-white;
        }
      }
    }
  }

  //This is needed for displaying table content in readonly format only
  .readonlytable tbody tr:hover {
    background-color: transparent !important;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  table.topaz-table {
    // Hides the caption
    caption {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    fa-icon {
      font-size: 1.5rem;
    }

    thead {
      th {
        @include typography.topaz-overline-style($typography-config);
        text-align: start;
      }
    }

    tbody {
      th {
        @include mat.m2-typography-level($typography-config, 'body-1');
        text-align: start;
      }

      tr {
        @media screen and (max-width: 600px) {
          margin: 1rem;
          padding-block: 1rem;
          padding-inline: 1.5rem;

          .topaz-table-mobile-header {
            display: block;
            font-weight: 600;
            @include typography.topaz-overline-style($typography-config);
          }
        }
      }
    }
  }

  table.topaz-table + .mat-mdc-paginator {
    font-size: mat.m2-font-size($typography-config, 'body-2');

    .mat-mdc-select-trigger,
    .mat-mdc-select-value {
      font-size: mat.m2-font-size($typography-config, 'body-2');
    }
  }
}

@mixin general() {
  //This is needed for displaying table content in readonly format only
  .readonlytable tbody tr:hover {
    filter: none !important;
  }

  .readonlytable th.cdk-header-cell {
    border-bottom: 0.063rem solid transparent;
  }

  .mat-sort-header.cdk-focused {
    //This is a focus method for sortable column headers with an inset box shadow
    @include o.topaz-focus-inset();
  }

  .mat-sort-header-container.mat-focus-indicator {
    border-bottom: none !important;
  }

  .mat-mdc-card.topaz-table-container {
    padding: var(--topaz-table-padding, 0) !important;
    overflow: hidden;

    .topaz-table-no-data {
      padding: 2rem;
    }
  }

  table.topaz-table:not(.with-scroll) {
    border-radius: v.$topaz-border-radius;
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    .mat-mdc-icon-button {
      .svg-inline--fa {
        vertical-align: -1px;
      }
    }

    thead {
      tr {
        height: 3.4375rem;
        th.topaz-batch-options {
          .mat-badge {
            height: 6px;
          }
        }
      }
    }

    @media screen and (min-width: $mobile-breakpoint-min) {
      tr {
        > *:first-child {
          padding-inline-start: 2rem;
        }

        > td.topaz-row-option,
        > th.topaz-row-option {
          text-align: end;
          padding-inline-end: 2rem;
        }
      }
    }

    tbody {
      tr {
        height: 3.4375rem;

        .topaz-table-mobile-header {
          display: none;
        }

        @media screen and (max-width: $mobile-breakpoint-max) {
          height: auto;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: auto 1fr;
          grid-auto-flow: row;
          align-items: center;
          margin: 0;
          padding: 1rem;
          row-gap: 1rem;

          td,
          th {
            grid-column: 1 / 4;
            display: grid;
            grid-template-columns: 100px 3fr;
            padding: 0;
            gap: 1.5rem;

            .topaz-table-mobile-header {
              display: inline;
              grid-column: 1 / 2;
            }

            &.topaz-row-selection {
              display: block;
              justify-self: start;
              grid-row: 1 / 2;
              grid-column: 1 / 2;
              margin-inline-end: 1rem;
              height: 1.5rem;
            }

            &.topaz-row-option {
              display: block;
              justify-self: end;
              grid-row: 1 / 2;
              grid-column: 3 / 4;
              height: 1.5rem;
            }
          }
        }
      }
    }

    thead {
      th:first-of-type {
        padding-inline-start: 2rem;

        @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'mobile')) {
          padding-inline-start: 1rem;
        }
      }

      th {
        padding-block: 0.75rem;
      }
    }

    th,
    td {
      padding: 0.25rem 0.75rem;
    }

    @media screen and (min-width: $mobile-breakpoint-min) {
      th.topaz-batch-options,
      td.topaz-row-selection {
        width: 110px;
      }
    }

    td.topaz-row-selection {
      padding-inline-start: 2rem;

      @media screen and (max-width: $mobile-breakpoint-max) {
        padding-inline-start: 0;
      }
    }
    thead {
      @media screen and (max-width: $mobile-breakpoint-max) {
        th {
          &:not(.topaz-batch-options) {
            display: none;
          }
        }
      }
    }
  }

  //table with scroll bar
  table.topaz-table {
    border-radius: v.$topaz-border-radius;
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    .mat-mdc-icon-button {
      .svg-inline--fa {
        vertical-align: -1px;
      }
    }

    thead {
      tr {
        height: 3.4375rem;
        th.topaz-batch-options {
          .mat-badge {
            height: 6px;
          }
        }
      }
    }

    @media screen and (min-width: $mobile-breakpoint-min) {
      tr {
        > *:first-child {
          padding-inline-start: 2rem;
        }

        > td.topaz-row-option,
        > th.topaz-row-option {
          text-align: end;
          padding-inline-end: 2rem;
        }
      }
    }

    tbody {
      tr {
        height: 3.4375rem;

        .topaz-table-mobile-header {
          display: none;
        }
      }
    }

    thead {
      th:first-of-type {
        padding-inline-start: 2rem;

        @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'mobile')) {
          padding-inline-start: 1rem;
        }
      }

      th {
        padding-block: 0.75rem;
      }
    }

    th,
    td {
      padding: 0.25rem 0.75rem;
    }

    @media screen and (min-width: $mobile-breakpoint-min) {
      th.topaz-batch-options,
      td.topaz-row-selection {
        width: 110px;
      }
    }

    td.topaz-row-selection {
      padding-inline-start: 2rem;

      @media screen and (max-width: $mobile-breakpoint-max) {
        padding-inline-start: 0;
      }
    }
  }

  .topaz-table + .mat-mdc-paginator {
    @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'mobile')) {
      &.topaz-paginator-sticky {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-block-start: -1rem;
      }
    }

    .mat-mdc-paginator-container {
      $form-field-width: 2.875rem;
      // 4.5rem = 72px @ 1rem == 16px
      height: 4.5rem;
      padding-inline: 1rem;
      flex-wrap: nowrap;
      align-items: center;

      .mat-mdc-paginator-page-size {
        // Hack to recenter element
        margin-top: -0.25rem;
        margin-inline: 0;

        .mat-mdc-paginator-page-size-label {
          margin: 0 0.5rem;
        }

        .mat-mdc-paginator-page-size-select {
          width: $form-field-width;
          margin: 0;
        }

        .mdc-text-field {
          padding: 0 4px;
        }
      }

      .mat-mdc-paginator-range-label {
        margin-inline-end: 1rem !important;
      }

      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'mobile')) {
        padding-inline: 0;
        padding-block-end: 0.25rem;
        justify-content: flex-end;
        gap: 1rem;
        padding-inline-end: 1rem;
        align-items: center;

        .mat-mdc-paginator-range-label {
          margin: 0;
          margin-inline-end: 1rem;
        }
      }
    }
  }

  .mobile-only {
    display: none !important;
  }

  @media screen and (max-width: $mobile-breakpoint-max) {
    .mobile-only {
      display: block !important;
    }
  }

  // This class is added for drag-drop table to view the cursor
  .drag-drop-cursor {
    cursor: move;
  }
  .drag-drop-cursor-fa-icon {
    margin-right: 0.25rem;
    font-size: 1rem !important;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config(t.$topaz-primary-theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }

  @include general();
}
