@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin general() {
  .mat-mdc-card {
    border-radius: v.$topaz-border-radius;
    padding: var(--topaz-card-padding, 2rem) !important;
    &:not([class*='mat-elevation-z']) {
      @include mat.elevation(5, $opacity: 0.04);
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16) !important;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
  @include general();
}
