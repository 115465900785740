@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .mat-divider {
    border-color: map.get(p.$topaz-neutral, 300);
  }
  .mat-mdc-list-item.mdc-list-item .mat-divider.mat-divider-inset {
    margin-left: 3.5rem;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin general() {
  .mat-divider {
    height: 100%;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
