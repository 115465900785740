@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/color-variables' as c;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
}

@mixin typography($theme) {
  .mat-mdc-radio-group {
    .mat-mdc-form-field-error {
      font-size: 75%;
    }
  }
}

@mixin general() {
  .topaz-radio-group {
    &-col {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 0.25em;
      ul {
        list-style-type: none;
      }
      @include _radio-group-margin();
    }
    &-row {
      display: flex;
      flex-direction: row;
      gap: 1.5em;
      @include _radio-group-margin();
    }
  }
}

@mixin _radio-group-margin() {
  margin: 1em 0;
  ul {
    margin-top: 0;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
