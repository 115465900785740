@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../variables-imports' as v;

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin general() {
  .topaz-breadcrumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 1.1875rem;
    .breadcrumb-item {
      color: var(--topaz-primary-600);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        font-size: 0.75rem;
      }
      &:hover {
        color: var(--topaz-primary-900);
      }
    }
    .breadcrumb-item-divider {
      color: var(--topaz-neutral-700);
      font-size: 0.875rem;
    }
    .breadcrumb-item-last {
      text-decoration-line: none;
      color: var(--topaz-neutral-700);
      pointer-events: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        font-size: 0.75rem;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
  @include general();
}
