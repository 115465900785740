@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/typography' as t;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $alert-palette: map.get($theme, 'alert');

  .mat-mdc-subheader {
    color: map.get(p.$topaz-neutral, 800);
  }

  .mdc-list-item {
    color: map.get(p.$topaz-neutral, 800);
    .mdc-list-item__start {
      color: map.get(p.$topaz-neutral, 900);
    }
    &:hover,
    &:active,
    &:focus {
      &::before {
        opacity: 0 !important;
      }
      background-color: mat.m2-get-color-from-palette(
        $primary-palette,
        50
      ) !important;
    }
  }

  .mat-mdc-list-item-line {
    color: map.get(p.$topaz-neutral, 800);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-list-item-icon {
    font-size: mat.m2-font-size($typography-config, 'headline-2');
  }

  .mdc-list-item {
    font-size: mat.m2-font-size($typography-config, 'body-1');
  }

  .mat-mdc-list-item-line {
    font-size: mat.m2-font-size($typography-config, 'body-1');
    font-weight: 400;
  }
}

@mixin general() {
  .mdc-list-item {
    .mdc-list-item__start {
      margin-right: 16px !important;
    }
    .mat-divider.mat-divider-inset {
      margin-left: 0px;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general();
}
