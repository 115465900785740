// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;

@use './components/component-themes' as components;
@use './material/custom-material-themes' as custom-mat;
@use './theme/themes';
@use './theme/typography';

// Plus imports for other components in your app.
@use './utilities/color-mixins';
@use './utilities/elevation';
@use './utilities/color-variables';
@use './utilities/variables';

// Custom components
@use './components/navigation';

// icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@mixin all-themes() {
  @include base-styles();

  @include core-theme();
  @include dark-theme();
  @include alt-theme();
  @include low-vision-theme();
}

@mixin core-theme($theme: null) {
  @include base-styles();

  @if $theme != null {
    @include mat.core();
    @include mat.all-component-typographies($theme);
    @include mat.all-component-themes($theme);
    @include color-mixins.custom-colors($theme);
    @include components.all-themes($theme);
    @include custom-mat.custom-themes($theme);

    // TODO(angular-migration): Looks like there is a bug with mat-typography.
    // It is now being set with mat-body-2 when it should be mat-body-1 based on their github...
    // https://github.com/angular/components/blob/505016c75a5aaec091f8b96ad1ae0f8c3a82374b/src/material/core/typography/_typography.scss#L270
    .mat-typography {
      @include mat.m2-typography-level(
        mat.m2-get-typography-config($theme),
        'body-1'
      );
    }
  } @else {
    // Include the common styles for Angular Material. We include this here so that you only
    // have to load a single css file for Angular Material in your app.
    // Be sure that you only ever include this mixin once!
    @include mat.core();

    // Include theme styles for core and each component used in your app.
    // Alternatively, you can import and @include the theme mixins for each component
    // that you are using.
    @include mat.all-component-typographies(themes.$topaz-primary-theme);
    @include mat.all-component-themes(themes.$topaz-primary-theme);
    @include color-mixins.custom-colors(themes.$topaz-primary-theme);
    @include components.all-themes(themes.$topaz-primary-theme);
    @include custom-mat.custom-themes(themes.$topaz-primary-theme);

    // TODO(angular-migration): Looks like there is a bug with mat-typography.
    // It is now being set with mat-body-2 when it should be mat-body-1 based on their github...
    // https://github.com/angular/components/blob/505016c75a5aaec091f8b96ad1ae0f8c3a82374b/src/material/core/typography/_typography.scss#L270
    .mat-typography {
      @include mat.m2-typography-level(
        mat.m2-get-typography-config(themes.$topaz-primary-theme),
        'body-1'
      );
    }
  }

  // Custom mixins
  @include elevation.elevation();
}

// Themes
@mixin dark-theme($theme: null) {
  @include base-styles();

  .topaz-dark {
    @include mat.all-component-colors($dark-theme);
    @include color-mixins.custom-colors($dark-theme);
    @include components.all-themes($dark-theme);
    @include custom-mat.custom-themes($dark-theme);
  }

  @if $theme != null {
    .topaz-dark {
      @include mat.all-component-colors($theme);
      @include color-mixins.custom-colors($theme);
      @include components.all-themes($theme);
      @include custom-mat.custom-themes($theme);
    }
  }
}

@mixin alt-theme($theme: null) {
  @include base-styles();

  .topaz-alt {
    @include mat.all-component-colors($alt-theme);
    @include color-mixins.custom-colors($alt-theme);
    @include components.all-themes($alt-theme);
    @include custom-mat.custom-themes($alt-theme);
  }

  @if $theme != null {
    .topaz-alt {
      @include mat.all-component-colors($theme);
      @include color-mixins.custom-colors($theme);
      @include components.all-themes($theme);
      @include custom-mat.custom-themes($theme);
    }
  }
}

@mixin low-vision-theme($theme: null) {
  @include base-styles();

  .topaz-low-vision {
    @include mat.all-component-typographies($low-vision-theme);
    @include components.all-themes($low-vision-theme);
    @include custom-mat.custom-themes($low-vision-theme);

    font-family: 'Atkinson Hyperlegible', 'Open Sans', sans-serif;
    font-size: 17px;
  }

  @if $theme != null {
    .topaz-low-vision {
      @include mat.all-component-typographies($theme);
      @include components.all-themes($theme);
      @include custom-mat.custom-themes($theme);

      font-family: 'Atkinson Hyperlegible', 'Open Sans', sans-serif;
      font-size: 17px;
    }
  }
}

@mixin base-styles() {
  html {
    scroll-behavior: smooth;
  }

  body {
    padding: 0;
    margin: 0;
    background-color: color-variables.$background-gray;
  }

  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;

    &.mat-typography {
      @include typography.topaz-title-margin();
    }
    vertical-align: middle !important;
  }

  @include typography.typography-utils();

  /**
 * Do not edit directly
 * Generated on Fri Nov 17 2023 15:37:13 GMT-0500 (Eastern Standard Time)
 * TODO: Please update the following files as well whenever this is generated.
 * 1 - apps/vue-documentation/src/app/pages/palette/code-snippets.ts
 * 2 - libs/ui-commons/assets/scss/theme/_palettes.scss
 */

  :root {
    --topaz-pearson-blue: rgb(0, 127, 163);
    --topaz-primary-50: rgb(228, 250, 255);
    --topaz-primary-100: rgb(166, 223, 247);
    --topaz-primary-200: rgb(135, 197, 225);
    --topaz-primary-300: rgb(99, 170, 202);
    --topaz-primary-400: rgb(69, 150, 184);
    --topaz-primary-500: rgb(29, 131, 166);
    --topaz-primary-700: rgb(0, 96, 125);
    --topaz-primary-800: rgb(0, 76, 104);
    --topaz-primary-900: rgb(0, 48, 87);
    --topaz-primary-600: rgb(0, 115, 148);
    --topaz-primary-alt-700: rgb(21, 161, 255);
    --topaz-primary-alt-400: rgb(47, 172, 255);
    --topaz-primary-alt-200: rgb(98, 192, 255);
    --topaz-primary-alt-100: rgb(149, 212, 255);
    --topaz-complementary-50: rgb(231, 246, 247);
    --topaz-complementary-100: rgb(194, 233, 234);
    --topaz-complementary-200: rgb(154, 219, 221);
    --topaz-complementary-300: rgb(113, 204, 207);
    --topaz-complementary-400: rgb(82, 193, 196);
    --topaz-complementary-600: rgb(47, 175, 179);
    --topaz-complementary-700: rgb(39, 166, 171);
    --topaz-complementary-800: rgb(33, 158, 163);
    --topaz-complementary-900: rgb(21, 142, 148);
    --topaz-complementary-500: rgb(52, 182, 186);
    --topaz-complementary-alt-700: rgb(72, 246, 255);
    --topaz-complementary-alt-400: rgb(98, 247, 255);
    --topaz-complementary-alt-200: rgb(149, 250, 255);
    --topaz-complementary-alt-100: rgb(200, 252, 255);
    --topaz-neutral-100: rgb(245, 246, 248);
    --topaz-neutral-200: rgb(240, 240, 240);
    --topaz-neutral-400: rgb(194, 194, 194);
    --topaz-neutral-500: rgb(153, 153, 153);
    --topaz-neutral-600: rgb(128, 128, 128);
    --topaz-neutral-800: rgb(51, 51, 51);
    --topaz-neutral-900-black: rgb(0, 0, 0);
    --topaz-neutral-700: rgb(102, 102, 102);
    --topaz-neutral-300-divider: rgb(223, 225, 225);
    --topaz-neutral-50-white: rgb(255, 255, 255);
    --topaz-warn-50: rgb(252, 232, 230);
    --topaz-warn-100: rgb(255, 201, 184);
    --topaz-warn-200: rgb(255, 165, 138);
    --topaz-warn-300: rgb(255, 129, 92);
    --topaz-warn-400: rgb(255, 100, 55);
    --topaz-warn-500: rgb(255, 69, 16);
    --topaz-warn-600: rgb(246, 63, 11);
    --topaz-warn-700: rgb(232, 55, 5);
    --topaz-warn-900: rgb(194, 29, 0);
    --topaz-warn-800: rgb(218, 49, 0);
    --topaz-warn-alt-700: rgb(255, 93, 93);
    --topaz-warn-alt-400: rgb(255, 118, 118);
    --topaz-warn-alt-200: rgb(255, 169, 169);
    --topaz-warn-alt-100: rgb(255, 220, 220);
    --topaz-alert-50: rgb(255, 248, 226);
    --topaz-alert-100: rgb(255, 236, 180);
    --topaz-alert-200: rgb(254, 224, 133);
    --topaz-alert-300: rgb(254, 213, 84);
    --topaz-alert-400: rgb(254, 202, 49);
    --topaz-alert-500: rgb(254, 193, 28);
    --topaz-alert-600: rgb(253, 179, 23);
    --topaz-alert-800: rgb(234, 134, 21);
    --topaz-alert-900: rgb(171, 76, 3);
    --topaz-alert-700: rgb(253, 162, 21);
    --topaz-alert-alt-700: rgb(255, 163, 78);
    --topaz-alert-alt-400: rgb(255, 176, 104);
    --topaz-alert-alt-200: rgb(255, 203, 155);
    --topaz-alert-alt-100: rgb(255, 229, 206);
    --topaz-success-50: rgb(241, 247, 229);
    --topaz-success-100: rgb(219, 235, 191);
    --topaz-success-200: rgb(195, 222, 149);
    --topaz-success-300: rgb(170, 208, 105);
    --topaz-success-400: rgb(151, 199, 69);
    --topaz-success-500: rgb(132, 189, 25);
    --topaz-success-600: rgb(116, 173, 16);
    --topaz-success-800: rgb(74, 134, 0);
    --topaz-success-900: rgb(30, 101, 0);
    --topaz-success-700: rgb(95, 154, 0);
    --topaz-success-alt-700: rgb(18, 255, 23);
    --topaz-success-alt-400: rgb(43, 255, 48);
    --topaz-success-alt-200: rgb(94, 255, 98);
    --topaz-success-alt-100: rgb(145, 255, 148);
    --topaz-accent-light-bright-orange-900: rgb(250, 144, 1);
    --topaz-accent-light-bright-orange-800: rgb(253, 162, 21);
    --topaz-accent-light-bright-orange-700: rgb(255, 187, 28);
    --topaz-accent-light-bright-orange-600: rgb(255, 211, 34);
    --topaz-accent-light-bright-orange-500: rgb(255, 227, 33);
    --topaz-accent-light-bright-orange-400: rgb(255, 232, 74);
    --topaz-accent-light-bright-orange-300: rgb(255, 238, 109);
    --topaz-accent-light-bright-orange-200: rgb(255, 243, 151);
    --topaz-accent-light-bright-orange-100: rgb(255, 248, 193);
    --topaz-accent-light-bright-orange-50: rgb(255, 252, 230);
    --topaz-accent-light-lime-green-900: rgb(134, 118, 0);
    --topaz-accent-light-lime-green-800: rgb(161, 156, 0);
    --topaz-accent-light-lime-green-700: rgb(178, 179, 0);
    --topaz-accent-light-lime-green-600: rgb(196, 201, 5);
    --topaz-accent-light-lime-green-500: rgb(210, 219, 14);
    --topaz-accent-light-lime-green-400: rgb(216, 224, 66);
    --topaz-accent-light-lime-green-300: rgb(224, 230, 103);
    --topaz-accent-light-lime-green-200: rgb(233, 237, 147);
    --topaz-accent-light-lime-green-100: rgb(242, 244, 190);
    --topaz-accent-light-lime-green-50: rgb(250, 251, 229);
    --topaz-accent-light-sky-blue-900: rgb(49, 110, 107);
    --topaz-accent-light-sky-blue-800: rgb(60, 146, 149);
    --topaz-accent-light-sky-blue-700: rgb(65, 166, 172);
    --topaz-accent-light-sky-blue-600: rgb(71, 188, 198);
    --topaz-accent-light-sky-blue-500: rgb(76, 205, 217);
    --topaz-accent-light-sky-blue-400: rgb(88, 213, 221);
    --topaz-accent-light-sky-blue-300: rgb(111, 221, 226);
    --topaz-accent-light-sky-blue-200: rgb(148, 231, 234);
    --topaz-accent-light-sky-blue-100: rgb(188, 240, 241);
    --topaz-accent-light-sky-blue-50: rgb(228, 249, 250);
    --topaz-accent-midtone-coral-pink-900: rgb(212, 13, 32);
    --topaz-accent-midtone-coral-pink-800: rgb(218, 26, 0);
    --topaz-accent-midtone-coral-pink-700: rgb(231, 29, 2);
    --topaz-accent-midtone-coral-pink-600: rgb(255, 51, 58);
    --topaz-accent-midtone-coral-pink-500: rgb(255, 61, 57);
    --topaz-accent-midtone-coral-pink-400: rgb(255, 80, 85);
    --topaz-accent-midtone-coral-pink-300: rgb(255, 117, 122);
    --topaz-accent-midtone-coral-pink-200: rgb(255, 157, 160);
    --topaz-accent-midtone-coral-pink-100: rgb(255, 208, 215);
    --topaz-accent-midtone-coral-pink-50: rgb(255, 236, 240);
    --topaz-accent-midtone-grass-green-900: rgb(0, 103, 35);
    --topaz-accent-midtone-grass-green-800: rgb(0, 134, 56);
    --topaz-accent-midtone-grass-green-700: rgb(18, 151, 67);
    --topaz-accent-midtone-grass-green-600: rgb(33, 170, 79);
    --topaz-accent-midtone-grass-green-500: rgb(43, 185, 89);
    --topaz-accent-midtone-grass-green-400: rgb(82, 196, 114);
    --topaz-accent-midtone-grass-green-300: rgb(116, 207, 139);
    --topaz-accent-midtone-grass-green-200: rgb(158, 220, 173);
    --topaz-accent-midtone-grass-green-100: rgb(196, 234, 205);
    --topaz-accent-midtone-grass-green-50: rgb(231, 247, 235);
    --topaz-accent-midtone-marine-torq-900: rgb(0, 72, 56);
    --topaz-accent-midtone-marine-torq-800: rgb(0, 100, 85);
    --topaz-accent-midtone-marine-torq-700: rgb(0, 116, 100);
    --topaz-accent-midtone-marine-torq-600: rgb(0, 132, 116);
    --topaz-accent-midtone-marine-torq-500: rgb(0, 144, 129);
    --topaz-accent-midtone-marine-torq-400: rgb(0, 161, 148);
    --topaz-accent-midtone-marine-torq-300: rgb(18, 178, 166);
    --topaz-accent-midtone-marine-torq-200: rgb(108, 200, 193);
    --topaz-accent-midtone-marine-torq-100: rgb(168, 221, 217);
    --topaz-accent-midtone-marine-torq-50: rgb(220, 241, 240);
    --topaz-accent-dark-royal-purple-900: rgb(88, 0, 94);
    --topaz-accent-dark-royal-purple-800: rgb(115, 0, 108);
    --topaz-accent-dark-royal-purple-700: rgb(130, 0, 115);
    --topaz-accent-dark-royal-purple-600: rgb(146, 0, 122);
    --topaz-accent-dark-royal-purple-500: rgb(158, 0, 126);
    --topaz-accent-dark-royal-purple-400: rgb(174, 53, 143);
    --topaz-accent-dark-royal-purple-300: rgb(189, 90, 162);
    --topaz-accent-dark-royal-purple-200: rgb(209, 137, 188);
    --topaz-accent-dark-royal-purple-100: rgb(228, 184, 214);
    --topaz-accent-dark-royal-purple-50: rgb(244, 227, 238);
    --topaz-accent-dark-fresh-green-900: rgb(27, 101, 0);
    --topaz-accent-dark-fresh-green-800: rgb(73, 135, 0);
    --topaz-accent-dark-fresh-green-700: rgb(95, 154, 0);
    --topaz-accent-dark-fresh-green-600: rgb(116, 173, 0);
    --topaz-accent-dark-fresh-green-500: rgb(132, 189, 0);
    --topaz-accent-dark-fresh-green-400: rgb(151, 199, 62);
    --topaz-accent-dark-fresh-green-300: rgb(171, 208, 100);
    --topaz-accent-dark-fresh-green-200: rgb(196, 221, 146);
    --topaz-accent-dark-fresh-green-100: rgb(220, 235, 189);
    --topaz-accent-dark-fresh-green-50: rgb(241, 247, 228);
    --topaz-accent-dark-midnight-blue-900: rgb(0, 48, 87);
    --topaz-accent-dark-midnight-blue-800: rgb(0, 65, 111);
    --topaz-accent-dark-midnight-blue-700: rgb(6, 74, 124);
    --topaz-accent-dark-midnight-blue-600: rgb(21, 84, 135);
    --topaz-accent-dark-midnight-blue-500: rgb(30, 92, 144);
    --topaz-accent-dark-midnight-blue-400: rgb(74, 115, 157);
    --topaz-accent-dark-midnight-blue-300: rgb(108, 140, 173);
    --topaz-accent-dark-midnight-blue-200: rgb(149, 172, 197);
    --topaz-accent-dark-midnight-blue-100: rgb(190, 205, 220);
    --topaz-accent-dark-midnight-blue-50: rgb(230, 235, 240);
  }
}
