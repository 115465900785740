@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $neutral-palette: map.get($theme, 'neutral');
  $tooltip-fill: null;
  $tooltip-border: null;
  @if $neutral-palette != null {
    $tooltip-fill: mat.m2-get-color-from-palette($neutral-palette, 800);
    $tooltip-border: mat.m2-get-color-from-palette($neutral-palette, 50);
  } @else {
    $tooltip-border: map.get(p.$topaz-neutral, 50);
    $tooltip-fill: map.get(p.$topaz-neutral, 800);
  }

  .mdc-tooltip .mdc-tooltip__surface {
    background-color: $tooltip-fill;
    border: 1px solid $tooltip-border;
  }
}

@mixin typography($theme) {
}

@mixin general($theme) {
  .mat-mdc-tooltip-trigger {
    cursor: pointer;
  }

  .mdc-tooltip .mdc-tooltip__surface {
    text-align: left;
    max-width: 320px;
    padding: 8px 12px;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general($theme);
}
