@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../theme/themes' as t;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  button,
  a {
    &.topaz-fab {
      cursor: pointer;

      &.topaz-fab-color-primary {
        @include _topaz-fab-color($primary-palette);
      }

      &.topaz-fab-color-secondary {
        @include _topaz-fab-color($primary-palette, false);
      }

      &.topaz-fab-floating-bot-right {
        position: fixed;
        inset-block-end: 2rem;
        inset-inline-end: 2rem;
      }

      &.topaz-fab-floating-bot-left {
        position: fixed;
        inset-block-end: 2rem;
        inset-inline-start: 2rem;
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  button,
  a {
    &.topaz-fab {
      // Icons
      &[topaz-icon-fab] {
        @include _topaz-icon-fab-base(56px);
      }

      &[topaz-icon-small-fab] {
        @include _topaz-icon-fab-base(40px);
      }

      // Text
      &[topaz-text-fab] {
        @include _topaz-text-fab-base($typography-config, 'body-2');
        padding: 18.5px 16px;
        line-height: 19px;
      }

      &[topaz-text-small-fab] {
        @include _topaz-text-fab-base($typography-config, 'body-2');
        padding: 7.5px 12px;
        line-height: 25px;
      }
    }
  }
}

@mixin theme($theme) {
  .topaz-fab {
    @include _topaz-fab-base();
  }

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}

@mixin _topaz-fab-color($palette, $isPrimary: true) {
  @if $isPrimary {
    $bg-color: mat.m2-get-color-from-palette($palette, 600);
    background-color: $bg-color;
    color: mat.m2-get-color-from-palette($palette, '600-contrast');
    border: none;

    &:hover {
      background-color: mat.m2-get-color-from-palette($palette, 800);
    }

    &:focus-visible {
      @include o.topaz-focus();
      outline: none;
    }

    &:active {
      background-color: color.scale($bg-color, $lightness: -3%);
    }
  } @else {
    $color: mat.m2-get-color-from-palette($palette, 600);
    color: $color;
    border: 2px solid $color;
    $bg-color: mat.m2-get-color-from-palette($palette, '600-contrast');
    background-color: $bg-color;

    &:hover {
      background-color: mat.m2-get-color-from-palette($palette, 50);
    }

    &:focus-visible {
      @include o.topaz-focus();
      outline: none;
    }

    &:active {
      background-color: color.scale($bg-color, $lightness: -3%);
    }
  }
}

@mixin _topaz-fab-base() {
  @include mat.elevation(6);
  z-index: 900;
}

@mixin _topaz-text-fab-base($theme, $body-level) {
  // 9999px is a css hack to create a pill shape
  border-radius: 9999px;
  @include mat.m2-typography-level($theme, $body-level);
  font-weight: 600;
  @include _adjust-fa-icon(-0.1em);

  .topaz-fab-holder {
    display: flex;
    align-items: baseline;
    gap: 0.5em;
  }
}

@mixin _topaz-icon-fab-base($size) {
  border-radius: 50%;
  width: $size;
  height: $size;

  @include _adjust-fa-icon();
}

@mixin _adjust-fa-icon($amt: -0.1875em) {
  fa-icon {
    .svg-inline--fa {
      vertical-align: $amt;
    }
  }
}
