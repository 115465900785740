@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/color-variables' as c;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  .topaz-checkbox-group {
    &-col,
    &-row {
      .mat-mdc-form-field-error {
        color: map.get(p.$topaz-red, 800);
      }
    }
  }
}

@mixin typography($theme) {
  .topaz-checkbox-group {
    &-col,
    &-row {
      .mat-mdc-form-field-error {
        font-size: 75%;
      }
    }
  }
}

@mixin general() {
  .topaz-checkbox-group {
    &-col {
      display: flex;
      flex-direction: column;
      ul {
        list-style-type: none;
      }
      gap: 0.25em;
      @include _checkbox-group-margin();
    }
    &-row {
      display: flex;
      align-content: center;
      flex-direction: row;
      gap: 0.75em;
    }
  }
}

@mixin _checkbox-group-margin() {
  margin: 1em 0;
  ul {
    margin-top: 0;
    li {
      margin-bottom: 0.25em;
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
