@use 'sass:map';
@use './variables';

$sizes: (
  '0': 0rem,
  '0_5': 0.5rem,
  '1': 1rem,
  '1_5': 1.5rem,
  '2': 2rem,
  '2_5': 2.5rem,
  '3': 3rem,
  '3_5': 3.5rem,
  '4': 4rem,
  '4_5': 4.5rem,
  '5': 5rem,
  '5_5': 5.5rem,
  '6': 6rem,
  '6_5': 6.5rem,
  '7': 7rem,
  '7_5': 7.5rem,
  '8': 8rem,
  '8_5': 8.5rem
);

@mixin one-column-layout() {
  html,
  body,
  .topaz-wrapper {
    height: 100%;
  }

  .topaz-wrapper {
    display: flex;
    flex-direction: column;
  }

  .topaz-content-wrapper {
    flex: 1 0 auto;
    margin-block-end: 2rem;

    .topaz-content-header {
      padding: 0px 2rem;
      h1 {
        padding-top: 32px;
      }
    }

    @media screen and (min-width: map.get(variables.$topaz-breakpoints-min, 'mobile')) {
      margin-block-end: 3rem;
    }

    @include _page-content();
  }

  .topaz-footer-wrapper {
    flex-shrink: 0;
  }
}

@mixin two-column-layout() {
  .topaz-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header header'
      'sidenav content'
      'sidenav footer';

    .topaz-header {
      grid-area: header;
      position: fixed;
      width: 100%;
      top: 0;
      // Always update the z-index in line 121 for mobile
      z-index: 1001;
    }

    .topaz-sidenav {
      // margin-top: 88px;
      grid-area: sidenav;
    }

    .topaz-content-wrapper {
      margin-top: 88px;
      grid-area: content;
      margin-block-end: 1rem;

      .topaz-content-header {
        padding: 0px 2rem;
        h1 {
          padding-top: 32px;
        }
      }

      @include _page-content();
    }

    .topaz-footer-wrapper {
      grid-area: footer;
    }
  }

  @media screen and (max-width: map.get(variables.$topaz-breakpoints-min, 'mobile')) {
    .topaz-wrapper[dir='rtl'] {
      .topaz-content-header {
        padding: 1rem 4rem 0px 0rem !important;
      }
    }

    .topaz-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .topaz-header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1001;
      }

      .topaz-content-wrapper {
        margin-top: 70px;
        margin-block-end: 2rem;

        .topaz-content-header {
          padding: 1rem 0px 0px 4rem;
          h1 {
            padding-top: 0px;
          }
        }

        h1:first-of-type {
          margin-inline-start: 0px;
        }

        @include _page-content();
      }
    }
  }
}

@mixin _page-content() {
  .topaz-page-content {
    padding-inline: var(--topaz-footer-padding, 1rem);
    @media screen and (min-width: map.get(variables.$topaz-breakpoints-min, 'x-small')) {
      padding-inline: 2rem;
    }
  }
}

/// Creates the margin utility css classes
@mixin margin-utils(
  $mobile: false,
  $small: false,
  $medium: false,
  $large: false
) {
  @include _create-margin();

  @if $large {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'large')) {
      @include _create-margin($prefix: large\:);
    }
  }

  @if $medium {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'medium')) {
      @include _create-margin($prefix: medium\:);
    }
  }

  @if $small {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'small')) {
      @include _create-margin($prefix: small\:);
    }
  }

  @if $mobile {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'mobile')) {
      @include _create-margin($prefix: mobile\:);
    }
  }
}

/// Creates the margin utility css classes
@mixin padding-utils(
  $mobile: false,
  $small: false,
  $medium: false,
  $large: false
) {
  @include _create-padding();

  @if $large {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'large')) {
      @include _create-padding($prefix: large\:);
    }
  }

  @if $medium {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'medium')) {
      @include _create-padding($prefix: medium\:);
    }
  }

  @if $small {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'small')) {
      @include _create-padding($prefix: small\:);
    }
  }

  @if $mobile {
    @media screen and (max-width: map.get(variables.$topaz-breakpoints-max, 'mobile')) {
      @include _create-padding($prefix: mobile\:);
    }
  }
}

@mixin _create-margin($prefix: null) {
  @if $prefix == null {
    @each $key, $value in $sizes {
      .m {
        // vertical

        /// Margin top
        &t {
          &-#{$key} {
            margin-block-start: $value !important;
          }
        }

        /// Margin bottom
        &b {
          &-#{$key} {
            margin-block-end: $value !important;
          }
        }

        /// Margin vertical
        &y {
          &-#{$key} {
            margin-block: $value !important;
          }
        }

        // Margin horizontal

        /// Margin left
        &l {
          &-#{$key} {
            margin-inline-start: $value !important;
          }
        }

        /// Margin right
        &r {
          &-#{$key} {
            margin-inline-end: $value !important;
          }
        }

        /// Margin horizontal
        &x {
          &-#{$key} {
            margin-inline: $value !important;
          }
        }

        /// All sides
        &-#{$key} {
          margin: $value !important;
        }
      }
    }
  } @else {
    .#{$prefix} {
      @each $key, $value in $sizes {
        &m {
          // vertical

          /// Margin top
          &t {
            &-#{$key} {
              margin-block-start: $value !important;
            }
          }

          /// Margin bottom
          &b {
            &-#{$key} {
              margin-block-end: $value !important;
            }
          }

          /// Margin vertical
          &y {
            &-#{$key} {
              margin-block: $value !important;
            }
          }

          // Margin horizontal

          /// Margin left
          &l {
            &-#{$key} {
              margin-inline-start: $value !important;
            }
          }

          /// Margin right
          &r {
            &-#{$key} {
              margin-inline-end: $value !important;
            }
          }

          /// Margin horizontal
          &x {
            &-#{$key} {
              margin-inline: $value !important;
            }
          }

          /// All sides
          &-#{$key} {
            margin: $value !important;
          }
        }
      }
    }
  }
}

@mixin _create-padding($prefix: null) {
  @if $prefix == null {
    @each $key, $value in $sizes {
      .p {
        // vertical

        /// Margin top
        &t {
          &-#{$key} {
            padding-top: $value !important;
          }
        }

        /// Margin bottom
        &b {
          &-#{$key} {
            padding-bottom: $value !important;
          }
        }

        /// Margin vertical
        &y {
          &-#{$key} {
            padding: $value 0 !important;
          }
        }

        // Margin horizontal

        /// Margin left
        &l {
          &-#{$key} {
            padding-left: $value !important;
          }
        }

        /// Margin right
        &r {
          &-#{$key} {
            padding-right: $value !important;
          }
        }

        /// Margin horizontal
        &x {
          &-#{$key} {
            padding: 0 $value !important;
          }
        }

        /// All sides
        &-#{$key} {
          padding: $value !important;
        }
      }
    }
  } @else {
    .#{$prefix} {
      @each $key, $value in $sizes {
        &p {
          // vertical

          /// Margin top
          &t {
            &-#{$key} {
              padding-block-start: $value !important;
            }
          }

          /// Margin bottom
          &b {
            &-#{$key} {
              padding-block-end: $value !important;
            }
          }

          /// Margin vertical
          &y {
            &-#{$key} {
              padding-block: $value !important;
            }
          }

          // Margin horizontal

          /// Margin left
          &l {
            &-#{$key} {
              padding-inline-start: $value !important;
            }
          }

          /// Margin right
          &r {
            &-#{$key} {
              padding-inline-end: $value !important;
            }
          }

          /// Margin horizontal
          &x {
            &-#{$key} {
              padding-inline: $value !important;
            }
          }

          /// All sides
          &-#{$key} {
            padding: $value !important;
          }
        }
      }
    }
  }
}
