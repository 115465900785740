@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/variables' as v;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-footer {
    background-color: map.get(p.$topaz-neutral, 50);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .topaz-footer {
    * {
      line-height: 0.75rem;
      font-size: 0.75rem;
    }
  }
}

@mixin general() {
  .topaz-footer-wrapper {
    grid-area: footer;
  }

  .topaz-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25em;

    padding: 0.5rem;

    @media screen and (min-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
      padding: 1rem;
    }

    p {
      margin: 0;
    }

    .topaz-footer-links {
      ul {
        margin: 0;
        padding: 0;
        display: flex;

        .mat-divider-vertical {
          border-color: mat.m2-get-color-from-palette(p.$topaz-neutral, 600);
        }

        li {
          list-style: none;
          margin: 0;
          display: inline;

          a {
            margin-inline: 1rem;
            text-decoration: underline;
          }
        }
      }
    }

    .topaz-footer-copyright {
      text-align: center;
      margin-inline: 0.5rem;
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
