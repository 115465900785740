@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/outline' as o;
@use '../utilities/color-variables' as c;
@use '../theme/palettes' as p;
@use '../utilities/functions' as f;
// For Mat Icon Buttons please use _icon-button-theme.scss
@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  button:not(.mdc-icon-button),
  a:not(.mdc-icon-button) {
    &:focus-visible.mat-mdc-button-base {
      @include o.topaz-focus();
    }

    //Buttons have an overlay for hover, disable that.
    .mat-mdc-button-persistent-ripple {
      display: none !important;
    }

    // Designers wanted this specifically for dark backgrounds. This *should* work for other themes too.
    &[disabled='true'].topaz-button-dark {
      &.mat-mdc-unelevated-button {
        background-color: map.get(p.$topaz-neutral, 700) !important;
        color: map.get(p.$topaz-neutral, 500) !important;
      }

      &.mat-mdc-outlined-button {
        border-color: map.get(p.$topaz-neutral, 500) !important;
        color: map.get(p.$topaz-neutral, 500) !important;
      }

      &.mat-mdc-button {
        color: map.get(p.$topaz-neutral, 500) !important;
      }
    }

    &[disabled='true'] {
      &.mat-mdc-unelevated-button {
        background-color: map.get(p.$topaz-neutral, 200) !important;
        color: map.get(p.$topaz-neutral, 400) !important;
      }

      &.mat-mdc-outlined-button {
        border-color: map.get(p.$topaz-neutral, 400) !important;
        color: map.get(p.$topaz-neutral, 400) !important;
      }

      &.mat-mdc-button {
        color: map.get(p.$topaz-neutral, 400) !important;
      }
    }

    &:not([disabled='true']) {
      // Mat flat butons
      &.mat-mdc-unelevated-button {
        // Primary color
        &.mat-primary {
          background-color: mat.m2-get-color-from-palette(
            $primary-palette,
            600
          );
          color: mat.m2-get-color-from-palette(
            $primary-palette,
            '600-contrast'
          );
          &:not(.topaz-button-dark):hover {
            background-color: mat.m2-get-color-from-palette(
              $primary-palette,
              800
            );
            color: mat.m2-get-color-from-palette(
              $primary-palette,
              '800-contrast'
            );
          }

          // Primary dark
          &.topaz-button-dark {
            background-color: mat.m2-get-color-from-palette(
              $primary-palette,
              '600-contrast'
            );
            color: mat.m2-get-color-from-palette($primary-palette, 600);

            &:hover {
              background-color: mat.m2-get-color-from-palette(
                $primary-palette,
                100
              );
              color: mat.m2-get-color-from-palette($primary-palette, 900);
            }
          }
        }

        // Warn color
        &.mat-warn {
          background-color: mat.m2-get-color-from-palette($warn-palette, 800);
          color: mat.m2-get-color-from-palette($warn-palette, '800-contrast');
          &:not(.topaz-button-dark):hover {
            background-color: mat.m2-get-color-from-palette($warn-palette, 900);
            color: mat.m2-get-color-from-palette($warn-palette, '900-contrast');
          }

          // Warn dark
          &.topaz-button-dark {
            background-color: mat.m2-get-color-from-palette(
              $warn-palette,
              '800-contrast'
            );
            color: mat.m2-get-color-from-palette($warn-palette, 800);

            &:hover {
              background-color: mat.m2-get-color-from-palette(
                $warn-palette,
                50
              );
              color: mat.m2-get-color-from-palette($warn-palette, 900);
            }
          }
        }

        // Accent color
        &.mat-accent {
          background-color: mat.m2-get-color-from-palette($accent-palette, 500);
          color: mat.m2-get-color-from-palette($accent-palette, '500-contrast');
          &:not(.topaz-button-dark):hover {
            background-color: mat.m2-get-color-from-palette(
              $accent-palette,
              600
            );
            color: mat.m2-get-color-from-palette(
              $accent-palette,
              '600-contrast'
            );
          }

          // Accent dark
          &.topaz-button-dark {
            background-color: mat.m2-get-color-from-palette(
              $accent-palette,
              900-contrast
            );
            color: mat.m2-get-color-from-palette($accent-palette, 900);

            &:hover {
              background-color: mat.m2-get-color-from-palette(
                $accent-palette,
                900
              );
              color: mat.m2-get-color-from-palette(
                $accent-palette,
                900-contrast
              );
            }
          }
        }
      }

      // Mat stroked buttons
      &.mat-mdc-outlined-button {
        // Primary color
        &.mat-primary {
          $primary-shade: 600;
          border-color: mat.m2-get-color-from-palette(
            $primary-palette,
            $primary-shade
          );
          color: mat.m2-get-color-from-palette(
            $primary-palette,
            $primary-shade
          );

          &:not(.topaz-button-dark):hover {
            background-color: mat.m2-get-color-from-palette(
              $primary-palette,
              50
            );
          }

          // Primary dark
          &.topaz-button-dark {
            border-color: map.get(p.$topaz-neutral, 50);
            color: map.get(p.$topaz-neutral, 50);

            &:hover {
              border-color: mat.m2-get-color-from-palette(
                $primary-palette,
                100
              );
              color: mat.m2-get-color-from-palette($primary-palette, 100);
            }
          }
        }

        // Warn color
        &.mat-warn {
          $warn-shade: 800;
          border-color: mat.m2-get-color-from-palette(
            $warn-palette,
            $warn-shade
          );
          color: mat.m2-get-color-from-palette($warn-palette, $warn-shade);

          &:not(.topaz-button-dark):hover {
            background-color: mat.m2-get-color-from-palette($warn-palette, 50);
          }

          // Warn dark
          &.topaz-button-dark {
            border-color: mat.m2-get-color-from-palette($warn-palette, 300);
            color: mat.m2-get-color-from-palette($warn-palette, 300);

            &:hover {
              border-color: mat.m2-get-color-from-palette($warn-palette, 100);
              color: mat.m2-get-color-from-palette($warn-palette, 100);
            }
          }
        }

        // Accent color
        &.mat-accent {
          $accent-shade: 900;
          border-color: mat.m2-get-color-from-palette(
            $accent-palette,
            $accent-shade
          );
          color: mat.m2-get-color-from-palette($accent-palette, $accent-shade);

          &:not(.topaz-button-dark):hover {
            background-color: mat.m2-get-color-from-palette(
              $accent-palette,
              50
            );
          }

          // Accent dark
          &.topaz-button-dark {
            border-color: map.get(p.$topaz-neutral, 50);
            color: map.get(p.$topaz-neutral, 50);

            &:hover {
              border-color: mat.m2-get-color-from-palette($accent-palette, 300);
              color: mat.m2-get-color-from-palette($accent-palette, 300);
            }
          }
        }
      }

      // Mat text buttons
      &.mat-mdc-button {
        // Basic color (gray)
        color: f.map-deep-get(p.$topaz-neutral, 700);
        &:not(.topaz-button-dark):hover {
          background-color: map.get(p.$topaz-neutral, 200);
          outline: 1px solid map.get(p.$topaz-neutral, 400);
        }

        // Basic (gray) dark
        &.topaz-button-dark {
          color: f.map-deep-get(p.$topaz-neutral, 50);

          &:hover {
            color: f.map-deep-get(p.$topaz-neutral, 800);
            background-color: f.map-deep-get(p.$topaz-neutral, 400);
          }
        }

        // Primary color
        &.mat-primary {
          color: mat.m2-get-color-from-palette($primary-palette, 600);

          &:not(.topaz-button-dark):hover {
            background-color: map.get(p.$topaz-neutral, 200);
          }

          // Primary dark
          &.topaz-button-dark {
            color: mat.m2-get-color-from-palette($primary-palette, 100);

            &:hover {
              background-color: mat.m2-get-color-from-palette(
                $primary-palette,
                50
              );
              color: mat.m2-get-color-from-palette(
                $primary-palette,
                '50-contrast'
              );
            }
          }
        }

        // Warn color
        &.mat-warn {
          color: mat.m2-get-color-from-palette($warn-palette, 800);

          // Warn dark
          &.topaz-button-dark {
            color: mat.m2-get-color-from-palette($warn-palette, 300);

            &:hover {
              background-color: mat.m2-get-color-from-palette(
                $warn-palette,
                50
              );
              color: mat.m2-get-color-from-palette($warn-palette, 200-contrast);
            }
          }
        }

        // Accent color
        &.mat-accent {
          color: mat.m2-get-color-from-palette($accent-palette, 900);

          // Accent dark
          &.topaz-button-dark {
            color: mat.m2-get-color-from-palette($accent-palette, 200);

            &:hover {
              background-color: mat.m2-get-color-from-palette(
                $accent-palette,
                200
              );
              color: mat.m2-get-color-from-palette(
                $accent-palette,
                200-contrast
              );
            }
          }
        }
      }
    }
  }

  .btn-menu-custom {
    border-color: transparent !important;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  button:not(.mdc-icon-button),
  a:not(.mdc-icon-button) {
    &.topaz-button-small {
      @include mat.m2-typography-level($typography-config, 'body-2');
      font-weight: 700;
    }
  }

  .btn-menu-custom {
    font-weight: 400 !important;
  }
}

@mixin general() {
  button:not(.mdc-icon-button),
  a:not(.mdc-icon-button) {
    &.nostyle {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .mat-mdc-button-base {
    width: fit-content;
  }

  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    padding-inline: 24px !important;

    &.topaz-button-small {
      padding-inline: 16px !important;
    }
  }

  .mat-mdc-button {
    padding-inline: 12px !important;

    &.topaz-button-small {
      padding-inline: 12px !important;
    }
  }

  .mat-mdc-button,
  .mat-mdc-unelevated-button {
    border-radius: 6px !important;
    height: 48px !important;
    box-sizing: content-box;

    &.topaz-button-small {
      height: 40px !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      position: relative;
    }

    fa-icon {
      margin-inline-start: 0.625em;
      vertical-align: baseline;
    }
  }

  .mat-mdc-outlined-button {
    border-width: 2px !important;
    border-radius: 6px !important;
    height: 48px !important;
    box-sizing: content-box;

    &.topaz-button-small {
      height: 40px !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      position: relative;
    }

    fa-icon {
      margin-inline-start: 0.625em;
      vertical-align: baseline;
    }
  }

  .btn-menu-custom {
    padding-inline: 0px !important;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
