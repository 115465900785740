@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/variables' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-mdc-dialog-surface {
    --mdc-dialog-container-color: var(
      --topaz-dialog-background,
      var(--topaz-neutral-50-white)
    );
  }

  .mat-mdc-dialog-content {
    --mat-dialog-with-actions-content-padding: 0 0 1rem 0;
    color: map.get(p.$topaz-neutral, 900) !important;
  }
}

@mixin typography($theme) {
}

@mixin general() {
  .mat-mdc-dialog-surface {
    padding: var(--topaz-dialog-container-padding, 2rem);
  }
  .topaz-dialog-header {
    @include _flex-with-gap();
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
      justify-content: space-between;
      align-items: baseline;
    }
  }
}

@mixin _flex-with-gap($value: 1em) {
  display: flex;
  gap: $value;
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general();
}
