@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables-imports' as v;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-side-sheet-container-content {
    background: map.get(p.$topaz-neutral, 50);
  }

  .topaz-side-sheet {
    .topaz-side-sheet-footer {
      border-top: 1px solid map.get(p.$topaz-neutral, 300);
    }
  }
}

@mixin typography($theme) {
  $config: mat.m2-get-typography-config($theme);
}

@mixin general($theme) {
  .topaz-side-sheet-container-content {
    $margin: 2rem;
    height: 100vh;
    margin-inline-start: $margin;
    width: calc(100vw - $margin);

    // above 600px screen width
    @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
      width: 600px;
    }
  }

  .topaz-side-sheet {
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr auto;

    .topaz-side-sheet-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-inline: 1.5rem;
      padding-block: 1.5rem 1rem;

      @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
        padding-inline: 2rem;
      }
    }

    .topaz-side-sheet-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: scroll;
      padding-inline: 1.5rem;
      padding-block-end: 2rem;
      height: 90%;
      @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
        padding-inline: 2rem;
        height: 100%;
      }
    }

    .topaz-side-sheet-footer {
      display: flex;
      justify-content: flex-end;
      padding-inline-end: 1.5rem;
      padding-block: 1rem;

      @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
        padding-block: 1.5rem 2rem;
        padding-inline-end: 2rem;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-color-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general($theme);
}
