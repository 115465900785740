@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/outline' as o;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  button,
  a {
    &:focus.mat-mdc-icon-button {
      .mat-mdc-button-persistent-ripple {
        @include o.topaz-focus();
      }
    }
    &.mat-mdc-icon-button {
      &:not([disabled='true']):hover {
        background-color: transparent;
        outline: none;

        .mat-mdc-button-persistent-ripple:before {
          @include o.topaz-hover-alpha;
        }
      }
    }
  }

  // dark theme for fa-icons
  .topaz-button-dark.mat-mdc-icon-button {
    .svg-inline--fa {
      color: map.get(p.$topaz-neutral, 50) !important;
    }
    &:hover {
      background-color: transparent;
      outline: none;
      .mat-mdc-button-persistent-ripple:before {
        display: none;
      }
      .svg-inline--fa {
        color: map.get($primary-palette, 100) !important;
      }
    }
  }
}
@mixin density($theme) {
  //Decreases touch area to 40px or density from config
  $density-config: mat.m2-get-density-config($theme);
  @if $density-config != null and $density-config != 0 {
    @include mat.icon-button-density($density-config);
  } @else {
    @include mat.icon-button-density(-2);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  button,
  a {
    &.topaz-button-small {
      @include mat.m2-typography-level($typography-config, 'body-2');
      font-weight: 700;
    }

    &.mat-mdc-icon-button:not(:hover) {
      .mat-mdc-button-persistent-ripple::before {
        display: none;
      }
    }

    &.mat-mdc-icon-button {
      .mat-mdc-button-persistent-ripple {
        transform: scale(0.75);
      }

      fa-icon svg {
        // fa-icon size input does not change when used with matButton.
        transform: scale(0.7) translateY(-1px);
      }

      .mat-mdc-button-ripple {
        transform: scale(0.85);
      }
    }
  }
}

@mixin general() {
}

@mixin theme($theme) {
  @include general();
  @include density($theme);

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
