@use 'sass:map';
@use '@angular/material' as mat;

@use '../variables-imports' as v;

@mixin _date-range-style($palette, $half: false) {
  $border-color: mat.m2-get-color-from-palette($palette, 500) !important;
  $border-style: solid 1px $border-color;

  border-block: $border-style;

  @if $half == true {
    border: $border-style;
  }

  background-color: mat.m2-get-color-from-palette($palette, 50) !important;
  color: mat.m2-get-color-from-palette($palette, '50-contrast') !important;
}

@mixin _unselected-cell {
  &:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    @content;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-calendar-body-selected {
    background-color: mat.m2-get-color-from-palette($primary-palette, 600);
    color: mat.m2-get-color-from-palette($primary-palette, '600-contrast');
  }

  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }

  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(
      .mat-calendar-body-comparison-identical
    ) {
    @include _date-range-style($primary-palette, true);
  }

  .mat-calendar-body-in-range {
    .mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected) {
        + .mat-calendar-body-cell-preview {
          @include _date-range-style($primary-palette);
        }
      }
    }
  }

  .mat-calendar-body-range-end:not(
      .mat-calendar-body-in-comparison-range
    )::before,
  .mat-calendar-body-range-start:not(
      .mat-calendar-body-in-comparison-range
    )::before {
    @include _date-range-style($primary-palette);
  }

  @media (hover: hover) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
      .mat-calendar-body-cell-content {
        @include _date-range-style($primary-palette, true);
      }
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
          .mat-calendar-body-comparison-identical
        ) {
        @include _date-range-style($primary-palette, true);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin general() {
  .mat-datepicker-content {
    padding-block-end: 0.5em;

    .mat-calendar {
      height: auto !important;
    }
  }

  .mat-calendar-body-label {
    opacity: 0;

    &[colspan='7'] {
      display: none;
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
