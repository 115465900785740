@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $neutral-palette: map.get($color-config, 'neutral');
  // Unselected track color
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
    background-color: map.get(p.$topaz-neutral, 700) !important;
  }

  // Selected track color
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background-color: mat.m2-get-color-from-palette(
      $primary-palette,
      100
    ) !important;
  }

  // Unselected Switch handle color and circle for a11y
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__handle::after {
    background-color: map.get(p.$topaz-neutral, 50) !important;
    border: 1px solid map.get(p.$topaz-neutral, 500) !important;
  }

  // Unselected switch icon's color
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__icon {
    fill: map.get(p.$topaz-neutral, 700) !important;
  }

  //hover
  .mdc-switch:enabled:hover .mat-mdc-slide-toggle-ripple {
    @include o.topaz-hover-alpha();
  }

  //focus
  .mdc-switch:enabled:focus .mat-mdc-slide-toggle-ripple {
    @include o.topaz-focus();
    //Transparent background
    background-color: rgba(0, 0, 0, 0);
  }

  // slide-toggle-ripple and switch__ripple layer colors when we hover on them
  .mat-mdc-slide-toggle .mdc-switch:enabled:focus .mdc-switch__ripple::after,
  .mat-mdc-slide-toggle .mdc-switch:enabled:hover .mdc-switch__ripple::after {
    opacity: 0 !important;
  }
}
@mixin density($theme) {
  // Decreases touch area to 40px or density from config
  $density-config: mat.m2-get-density-config($theme);
  @if $density-config != null and $density-config != 0 {
    @include mat.slide-toggle-density($density-config);
  } @else {
    @include mat.slide-toggle-density(-2);
  }
}

@mixin typography($theme) {
  .mdc-switch {
    margin-inline-end: 0.7em !important;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icon,
  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__icon {
    width: 1em !important;
    height: 1em !important;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include density($theme);
}
