@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables-imports' as v;
@use '../theme/palettes' as p;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-global-bar {
    background-color: mat.m2-get-color-from-palette($primary-palette, 900);
    color: mat.m2-get-color-from-palette($primary-palette, '900-contrast');
    border-block-end: 0.3125rem solid map.get(p.$topaz-neutral, 400);
  }

  mat-tab-group {
    background: map.get(p.$topaz-neutral, 50);
  }

  .global-tab-bar-container {
    .middle {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzc2IiBoZWlnaHQ9IjM3IiB2aWV3Qm94PSIwIDAgMzc2IDM3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMzc1LjAwOCAzN0wzNzUuMDExIDBDMjE4LjE5NCAzOS42OTI2IDY0Ljk5NjIgMTcuOTk5NiAwLjAxMjA0NDkgMC4yMTIzMDVDMC4wMTIwNDQ5IDAuMjEyMzA1IDAuMDAwMzQyODQ2IDM2LjcwMzcgMCAzN0gzNzUuMDA4WiIgZmlsbD0iI0Y1RjZGOCIvPgo8L3N2Zz4K');
      background-size: cover;
    }
    .bottom {
      background: map.get(p.$topaz-neutral, 100);
    }
    .end {
      background: map.get(p.$topaz-neutral, 100);
      border-top: 2px solid map.get(p.$topaz-neutral, 50);
    }

    .dynamic-content-end {
      background: map.get(p.$topaz-neutral, 100);
      border-top: 2px solid map.get(p.$topaz-neutral, 50);
      padding: 2em 1.5em;
    }
    .global-bar-mobile-container {
      h2 {
        color: map.get(p.$topaz-neutral, 900);
      }
      .subview-links {
        a.topaz-nav-link-mobile {
          color: map.get(p.$topaz-neutral, 900);
          &:hover {
            color: map.get(p.$topaz-neutral, 900);
          }

          &:active {
            color: mat.m2-get-color-from-palette($primary-palette, 700);
          }
          &:focus,
          &:focus-visible {
            box-shadow: 0 0 0.125em 0.25em map-get(p.$topaz-complementary, 800);
          }
        }
      }

      h2.active-parent-item {
        color: mat.m2-get-color-from-palette($primary-palette, 600);
      }
    }

    .sidenav {
      box-shadow: 3px 0 6px rgba(0, 0, 0, 0.24);
    }

    .topaz-bar-header-container {
      background-color: map.get(p.$topaz-neutral, 50);
    }

    mat-toolbar {
      background-color: mat.m2-get-color-from-palette($primary-palette, 900);
      color: white;
    }

    .mat-mdc-tab:not(.mdc-tab--active) {
      &:hover {
        background: map.get(p.$topaz-complementary, 100);
        border-radius: 0.7rem 0.7rem 0 0 !important;
      }
    }

    .mat-mdc-tab:hover {
      background: mat.m2-get-color-from-palette($primary-palette, 900);
    }

    .mat-mdc-tab:focus-visible {
      border-bottom: 0.125rem solid map-get(p.$topaz-complementary, 800);
    }

    .mat-mdc-tab.mdc-tab-indicator--active:focus-visible {
      background-color: mat.m2-get-color-from-palette(
        $primary-palette,
        900
      ) !important;
    }

    .mdc-tab--active {
      background: mat.m2-get-color-from-palette($primary-palette, 900);
    }

    .mdc-tab--active .mdc-tab__text-label {
      color: mat.m2-get-color-from-palette(
        $primary-palette,
        '900-contrast'
      ) !important;
    }

    .mdc-tab__text-label {
      color: mat.m2-get-color-from-palette($primary-palette, 900) !important;
    }
  }
  a.topaz-nav-link-mobile {
    color: map.get(p.$topaz-neutral, 900);
    width: fit-content;
    &:hover {
      color: map.get(p.$topaz-neutral, 900);
    }

    &:active {
      color: mat.m2-get-color-from-palette($primary-palette, 700);
    }
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0.125em 0.25em map-get(p.$topaz-complementary, 800);
    }
  }
}

@mixin typography($theme) {
}

@mixin general() {
  .topaz-bar-header-container {
    mat-tab-group {
      .mat-mdc-tab-header {
        padding-bottom: 0 !important;
      }

      .mat-mdc-tab-header .mat-mdc-tab {
        flex-grow: 0 !important;
        --mdc-secondary-navigation-tab-container-height: 2.25rem;
      }
      .mat-mdc-tab-labels {
        column-gap: 1em;
        padding-left: 2em;
      }
    }
  }
  .global-bar-mobile-container {
    display: flex;
    flex-direction: column;
    padding-left: 0.25rem;
    h2 {
      overflow: hidden;
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;
      font-size: 1em;
      font-style: normal;
      font-weight: 600;
      line-height: 1em;
    }

    .subview-links {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 0.5em;
      a {
        padding: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        text-decoration: none;

        &:active {
          font-weight: 700;
        }
        &:focus,
        &:focus-visible {
          border-radius: 0.25rem;
        }
      }
    }

    .parent-links {
      display: flex;
      padding: 1.5em 1em 2em 1em;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      flex: 1 0 0;
      align-self: stretch;
    }
    h2.active-parent-item {
      overflow: hidden;
      font-feature-settings: 'clig' off, 'liga' off;
      text-overflow: ellipsis;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0.5rem;
    }
  }

  .global-tab-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .sidenav-container {
      height: 100%;
      width: 100%;
    }

    .sidenav {
      width: 100%;
    }

    .hidden {
      display: none;
    }

    .spacer {
      flex: 1 1 auto;
    }

    .middle {
      min-height: 2rem;
      margin-top: 0.5rem;
    }

    .end {
      align-items: center;
      padding: 3rem 2rem;
      button {
        width: 100%;
      }
    }

    .topaz-bar-header-container {
      display: grid;
      grid-template-columns: 60% 40%;
      .header-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1rem;
      }
    }

    @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
      .toolbar-link-container,
      .sidenav {
        display: none;
      }
    }

    @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
      mat-toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .sidenav-container {
        height: 100%;
        width: 100%;
      }
      .side-nav-container-opened {
        height: 100vh !important;
      }
    }

    topaz-global-bar {
      position: relative !important;
    }

    .custom-global-bar-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 2em;
      fa-icon {
        cursor: pointer;
      }
    }

    .desktop-content-container {
      display: flex;
      flex-direction: row;
      column-gap: 0.75rem;
      font-size: 0.875rem;
      padding-top: 0.25em;
    }

    .close-icon {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0.5em;
    }

    .mdc-tab--active {
      border: none !important;
      &:hover {
        cursor: default;
      }
    }

    .mdc-tab__text-label {
      font-weight: 600;
    }

    .mat-mdc-tab.mdc-tab {
      border-radius: 0.6rem 0.6rem 0 0 !important;
    }

    .mdc-tab-indicator__content--underline,
    .mat-mdc-tab-list {
      border: none !important;
    }
  }
  .topaz-global-bar {
    display: grid;
    grid-template-columns: min-content max-content auto auto;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'logo start center end';
    align-items: center;
    .topaz-global-bar-section {
      display: flex;
      align-items: center;
    }

    .topaz-global-bar-logo {
      margin-inline: 2rem;
      margin-block-start: 1.5rem;
      // subtracting 5 px because of border
      margin-block-end: calc(1.5rem - 5px);
      height: 2.5rem;

      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        margin-inline: 1rem;
        margin-block-start: 0.5rem;
        margin-block-end: 0.33rem;
      }
      grid-area: logo;
    }

    .topaz-global-bar-section-start {
      margin-right: 2rem;
      grid-area: start;
      justify-content: start;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        margin-inline: 1rem;
        margin-right: 1rem;
      }
    }

    .topaz-global-bar-section-center {
      margin-right: 2rem;
      grid-area: center;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        margin-inline: 1rem;
        margin-right: 1rem;
      }
    }

    .topaz-global-bar-section-end {
      margin-right: 2rem;
      grid-area: end;
      justify-content: flex-end;
      &:dir(rtl) {
        margin-left: 2rem;
      }
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
        margin-inline: 1rem;
        margin-right: 1rem;
      }
    }

    @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
      grid-template-areas:
        'logo start start end'
        'center center center center';

      .topaz-global-bar-logo {
        grid-area: logo;
        margin-block-start: 12px;
        margin-block-end: 8px;
        margin-inline: 16px;
      }

      .topaz-global-bar-section-start {
        padding: 0;
      }

      .topaz-global-bar-section-center {
        padding: 0;
        justify-content: flex-start;
        border-inline-start: none;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }

  @include general();
}
