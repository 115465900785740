@use 'sass:map';
@use '../theme/palettes' as p;

@mixin topaz-focus() {
  //Please  update @mixin topaz-focus-inset with any changes if made in future to topaz-focus, as its the same styling with inset box shadow.
  box-shadow: 0 0 2px 4px map-get(p.$topaz-complementary, 800) !important;
  opacity: 1 !important;
}

@mixin topaz-focus-dark-anchor() {
  //Please  update @mixin topaz-focus-inset with any changes if made in future to topaz-focus, as its the same styling with inset box shadow.
  box-shadow: 0 0 1px 2px map-get(p.$topaz-complementary, 800) !important;
  opacity: 1 !important;
}

//This is a focus method to set the box shadow inside
@mixin topaz-focus-inset() {
  box-shadow: 0 0 2px 4px map-get(p.$topaz-complementary, 800) inset !important;
  opacity: 1 !important;
}

//This is hover border that uses alpha rather than the grey.
@mixin topaz-hover-alpha() {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.24);
  background-color: rgba(0, 0, 0, 0.06) !important;
  opacity: 1 !important;
}
