@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $alert-palette: map.get($theme, 'alert');

  $fill-opacity: 0.15;

  svg {
    &.info {
      circle {
        fill: mat.m2-get-color-from-palette($primary-palette, 300);
      }
      path.flag {
        fill: mat.m2-get-color-from-palette($primary-palette, 300);
      }
    }

    &.success {
      ellipse {
        fill: mat.m2-get-color-from-palette($accent-palette);
      }

      path.check {
        fill: mat.m2-get-color-from-palette($accent-palette);
      }
    }

    &.warn {
      ellipse {
        fill: mat.m2-get-color-from-palette($warn-palette, 800);
      }

      path.triangle {
        fill: mat.m2-get-color-from-palette($warn-palette, 800);
      }
    }

    @if $alert-palette != null {
      &.alert {
        ellipse {
          fill: map.get($alert-palette, 700);
        }
        path.bell {
          fill: map.get($alert-palette, 700);
        }
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin general() {
  .topaz-social-media-icon {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 2.5rem;
    width: 2.5rem;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-typography-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  @include general();
}
