@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $alert-palette: map.get($theme, 'alert');
  $complementary-palette: map.get($theme, 'complementary');
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: map.get(p.$topaz-neutral, 300);
  }
  .topaz-progress-bar-variation {
    &-primary {
      .mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
        border-color: mat.m2-get-color-from-palette($primary-palette, 700);
      }
    }

    &-accent {
      .mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
        border-color: mat.m2-get-color-from-palette($accent-palette, 800);
      }
    }

    &-warn {
      .mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
        border-color: mat.m2-get-color-from-palette($warn-palette, 800);
      }
    }

    @if $alert-palette != null {
      &-alert {
        .mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
          border-color: mat.m2-get-color-from-palette($alert-palette, 800);
        }
      }
    }

    @if $complementary-palette != null {
      &-complementary {
        .mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
          border-color: mat.m2-get-color-from-palette(
            $complementary-palette,
            800
          );
        }
      }
    }
  }

  .topaz-progress-bar-label {
    color: map.get(p.$topaz-neutral, 700);
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .topaz-progress-bar-label {
    @include mat.m2-typography-level($typography-config, 'body-2');
  }
}

@mixin general() {
  .topaz-progress-bar .mat-mdc-progress-bar {
    --mdc-linear-progress-track-height: 0.5rem;
    --mdc-linear-progress-active-indicator-height: 0.5rem;
    border-radius: 0.5rem;
  }

  .topaz-progress-bar-label {
    display: inline-block;
    margin-bottom: 0.75rem;
  }
}

@mixin theme($theme) {
  @include general();
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general();
}
