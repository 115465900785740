@use '@angular/material' as mat;
@use 'sass:map';
@use '../theme/palettes' as p;
@use '../utilities/variables' as v;

@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
$topaz-typography-h1: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1.75rem,
  $line-height: 2.375rem
);
$topaz-typography-h2: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1.565rem,
  $line-height: 2.125rem
);
$topaz-typography-h3: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1.399rem,
  $line-height: 1.9375rem
);
$topaz-typography-h4: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1.251rem,
  $line-height: 1.6875rem
);
$topaz-typography-h5: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1.118rem,
  $line-height: 1.5rem
);
$topaz-typography-h6: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1rem,
  $line-height: 1.375rem
);
$topaz-typography-body-2: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: 0.875rem,
  $line-height: 1.375rem
);
$topaz-typography-body-1: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: 16px,
  $line-height: 1.5rem
);
$topaz-typography-button: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 1rem
);
$topaz-typography-overline: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 700,
  $font-size: 0.75rem
);
$topaz-typography-input: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1.3
);
$topaz-typography-caption: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: 0.75rem,
  $line-height: 1rem
);

$topaz-typography: mat.m2-define-typography-config(
  $font-family: 'Open Sans',
  $headline-1: $topaz-typography-h1,
  $headline-2: $topaz-typography-h2,
  $headline-3: $topaz-typography-h3,
  $headline-4: $topaz-typography-h4,
  $headline-5: $topaz-typography-h1,
  $headline-6: $topaz-typography-h2,
  $subtitle-1: $topaz-typography-body-1,
  $subtitle-2: $topaz-typography-body-2,
  $body-1: $topaz-typography-body-1,
  $body-2: $topaz-typography-body-2,
  $caption: $topaz-typography-caption,
  $button: $topaz-typography-button
);

// Low Vision
$topaz-low-vision-typography-h1: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(40px + 1px)
);
$topaz-low-vision-typography-h2: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(28px + 1px)
);
$topaz-low-vision-typography-h3: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(24px + 1px)
);
$topaz-low-vision-typography-h4: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(20px + 1px)
);
$topaz-low-vision-typography-h5: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(16px + 1px)
);
$topaz-low-vision-typography-body-1: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 400,
  $font-size: calc(16px + 1px)
);
$topaz-low-vision-typography-body-2: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 400,
  $font-size: calc(14px + 1px)
);
$topaz-low-vision-typography-button: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(16px + 1px)
);
$topaz-low-vision-typography-overline: mat.m2-define-typography-level(
  $font-family: 'Atkinson Hyperlegible',
  $font-weight: 600,
  $font-size: calc(10px + 1px)
);
$topaz-low-vision-typography-caption: mat.m2-define-typography-level(
  $font-family: 'Open Sans',
  $font-weight: 400,
  $font-size: calc(10px + 1px)
);

$topaz-low-vision-typography: mat.m2-define-typography-config(
  $font-family: 'Atkinson Hyperlegible',
  $headline-1: $topaz-low-vision-typography-h1,
  $headline-2: $topaz-low-vision-typography-h2,
  $headline-3: $topaz-low-vision-typography-h3,
  $headline-4: $topaz-low-vision-typography-h4,
  $headline-5: $topaz-low-vision-typography-h1,
  $headline-6: $topaz-low-vision-typography-h2,
  $subtitle-1: $topaz-low-vision-typography-h3,
  $subtitle-2: $topaz-low-vision-typography-h4,
  $body-1: $topaz-low-vision-typography-body-1,
  $body-2: $topaz-low-vision-typography-body-2,
  $caption: $topaz-low-vision-typography-caption,
  $button: $topaz-low-vision-typography-button
);

@mixin topaz-overline-style($config) {
  font-family: mat.m2-font-family($config, 'body-1');
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: map.get(p.$topaz-neutral, 700);
  letter-spacing: 1.25px;
}

@mixin topaz-title-margin() {
  %h-base-style {
    color: map.get(p.$topaz-neutral, 900);
  }

  %h1-style {
    margin-block-end: 16px;

    @media screen and (min-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
      margin-block-end: 24px;
    }
  }

  %h2-style {
    margin-block-end: 16px;
  }

  %h3-style {
    margin-block-end: 12px;
  }

  %h4-style {
    margin-block-end: 8px;
  }

  %h5-style {
    @extend %h4-style;
    font-size: 1.118rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  %h6-style {
    margin-block: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
  }

  .topaz-title {
    @extend %h-base-style;

    &.mat-headline-1 {
      @extend %h1-style;
    }

    &.mat-headline-2 {
      @extend %h2-style;
    }

    &.mat-headline-3 {
      @extend %h3-style;
    }

    &.mat-headline-4 {
      @extend %h4-style;
    }

    // mat design doesn't use h5 or h6
    &.topaz-title-5 {
      @extend %h5-style;
    }

    &.topaz-title-6 {
      @extend %h6-style;
    }
  }

  h1:not(.topaz-title) {
    @extend %h1-style;
  }

  h2:not(.topaz-title) {
    @extend %h2-style;
  }

  h3:not(.topaz-title) {
    @extend %h3-style;
  }

  h4:not(.topaz-title) {
    @extend %h4-style;
  }

  h5:not(.topaz-title) {
    @extend %h5-style;
  }

  h6:not(.topaz-title) {
    @extend %h5-style;
  }
}

@mixin typography-utils() {
  %topaz-headline {
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  %topaz-h1 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.75rem;
    @extend %topaz-headline;
  }
  .topaz-headline-1 {
    @extend %topaz-h1;
  }

  %topaz-h2 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.565rem;
    @extend %topaz-headline;
  }

  .topaz-headline-2 {
    @extend %topaz-h2;
  }

  %topaz-h3 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.399rem;
    @extend %topaz-headline;
  }
  .topaz-headline-3 {
    @extend %topaz-h3;
  }

  %topaz-h4 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.251rem;
    @extend %topaz-headline;
  }

  .topaz-headline-4 {
    @extend %topaz-h4;
  }

  %topaz-h5 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.118rem;
    @extend %topaz-headline;
  }

  .topaz-headline-5 {
    @extend %topaz-h5;
  }

  %topaz-h6 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1rem;
    @extend %topaz-headline;
  }
  .topaz-headline-6 {
    @extend %topaz-h6;
  }

  %topaz-body {
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .topaz-body-1 {
    font-size: 1rem;
    @extend %topaz-body;
  }

  .topaz-body-2 {
    font-size: 0.875rem;
    @extend %topaz-body;
  }

  %topaz-button {
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .topaz-button-label-large {
    font-size: 1rem;
    @extend %topaz-button;
  }

  /* Button label/Label--MD */

  .topaz-button-label-small {
    font-size: 0.875rem;
    @extend %topaz-button;
  }

  /* Button label/Label--SM */
  .topaz-button-label-xsmall {
    font-size: 0.75rem;
    @extend %topaz-button;
  }

  .topaz-subtext {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .topaz-overline {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem; /* 133.333% */
    letter-spacing: 0.078125rem;
    text-transform: uppercase;
  }

  //4, 8, 12, 16, 20, 24, 28, 32

  $spacing-values: (0, 4, 8, 12, 16, 20, 24, 28, 32);

  @each $value in $spacing-values {
    .topaz-margin-#{$value} {
      margin: #{$value}px;
    }
    .topaz-margin-top-#{$value} {
      margin-top: #{$value}px;
    }

    .topaz-margin-bottom-#{$value} {
      margin-bottom: #{$value}px;
    }

    .topaz-margin-left-#{$value} {
      margin-left: #{$value}px;
    }

    .topaz-margin-right-#{$value} {
      margin-right: #{$value}px;
    }

    .topaz-margin-x-#{$value} {
      margin-left: #{$value}px;
      margin-right: #{$value}px;
    }

    .topaz-margin-y-#{$value} {
      margin-top: #{$value}px;
      margin-bottom: #{$value}px;
    }
  }

  @each $value in $spacing-values {
    .topaz-padding-#{$value} {
      padding: #{$value}px;
    }
    .topaz-padding-top-#{$value} {
      padding-top: #{$value}px;
    }

    .topaz-padding-bottom-#{$value} {
      padding-bottom: #{$value}px;
    }

    .topaz-padding-left-#{$value} {
      padding-left: #{$value}px;
    }

    .topaz-padding-right-#{$value} {
      padding-right: #{$value}px;
    }

    .topaz-padding-x-#{$value} {
      padding-left: #{$value}px;
      padding-right: #{$value}px;
    }
    .topaz-padding-y-#{$value} {
      padding-top: #{$value}px;
      padding-bottom: #{$value}px;
    }
  }
}
