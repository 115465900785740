@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/outline' as o;

@mixin color($theme) {
  // get color config
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-chip {
    --mdc-chip-elevated-container-color: var(--topaz-complementary-100);
    --mdc-chip-label-text-color: var(--topaz-primary-800);
    &:hover {
      --mdc-chip-elevated-container-color: var(--topaz-complementary-200);
    }
    fa-icon {
      color: mat.m2-get-color-from-palette(p.$topaz-neutral, 700);
    }
    &.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(
        .mdc-evolution-chip--disabled
      ):not([color='accent']):not([color='warn']) {
      --mdc-chip-elevated-selected-container-color: var(
        --topaz-complementary-300
      );
      --mdc-chip-selected-label-text-color: var(--topaz-primary-800);
      --mdc-chip-with-icon-selected-icon-color: var(--topaz-primary-800);
    }
    &:not([color='accent']):not([color='warn']) {
      &.cdk-focused {
        --mdc-chip-elevated-container-color: var(--topaz-complementary-300);
        @include o.topaz-focus();
      }
    }

    &[color='accent'] {
      --mdc-chip-elevated-container-color: var(
        --topaz-accent-dark-royal-purple-50
      );
      --mdc-chip-label-text-color: var(--topaz-accent-dark-royal-purple-700);
      &.mat-mdc-chip-selected {
        --mdc-chip-elevated-selected-container-color: var(
          --topaz-accent-dark-royal-purple-200
        );
        --mdc-chip-selected-label-text-color: var(
          --topaz-accent-dark-royal-purple-700
        );
        --mdc-chip-with-icon-selected-icon-color: var(
          --topaz-accent-dark-royal-purple-700
        );
      }
      &:hover {
        --mdc-chip-elevated-container-color: var(
          --topaz-accent-dark-royal-purple-100
        );
      }
      &.cdk-focused {
        --mdc-chip-elevated-container-color: var(
          --topaz-accent-dark-royal-purple-200
        );
      }
    }
    &[color='warn'] {
      --mdc-chip-elevated-container-color: var(--topaz-warn-50);
      --mdc-chip-label-text-color: var(--topaz-warn-900);
      &.mat-mdc-chip-selected {
        --mdc-chip-elevated-selected-container-color: var(--topaz-warn-200);
        --mdc-chip-selected-label-text-color: var(--topaz-warn-900);
        --mdc-chip-with-icon-selected-icon-color: var(--topaz-warn-900);
      }
      &:hover {
        --mdc-chip-elevated-container-color: var(--topaz-warn-100);
      }
      &.cdk-focused {
        --mdc-chip-elevated-container-color: var(--topaz-warn-200);
      }
    }
    &:focus,
    &:focus-visible {
      @include o.topaz-focus();
    }
  }
  mat-chip-option,
  mat-chip-row {
    &:focus,
    &:focus-visible {
      @include o.topaz-focus();
    }
  }
}

@mixin typography($theme) {
  // get typography config
  $typography-config: mat.m2-get-typography-config($theme);
  .topaz-chip {
    white-space: pre-wrap;
  }
}

@mixin general($theme) {
  .topaz-chip {
    fa-icon {
      svg {
        transform: translateY(1px);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }

  @include general($theme);
}
