@use 'sass:map';
@use '@angular/material' as mat;
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  button.topaz-spinner,
  a.topaz-spinner {
    &.spinner-show {
      color: transparent !important;
      pointer-events: none;
    }
  }

  // https://dkreider.medium.com/how-to-add-a-spinner-to-an-angular-material-button-the-simplest-way-ever-69e2f7005f29
  button.topaz-spinner.spinner-show:before,
  a.topaz-spinner.spinner-show:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid v.$ui-white;
    border-top-color: transparent;
    animation: spinner 0.8s linear infinite;
  }

  button.topaz-spinner.spinner-show.mat-mdc-outlined-button:before,
  a.topaz-spinner.spinner-show.mat-mdc-outlined-button:before {
    border-color: mat.m2-get-color-from-palette($primary-palette);
    border-top-color: transparent;
  }

  button.topaz-spinner.spinner-show.mat-mdc-outlined-button.mat-warn:before,
  a.topaz-spinner.spinner-show.mat-mdc-outlined-button.mat-warn:before {
    border-color: mat.m2-get-color-from-palette($warn-palette);
    border-top-color: transparent;
  }
  button.topaz-spinner.spinner-show.mat-mdc-outlined-button.mat-accent:before,
  a.topaz-spinner.spinner-show.mat-mdc-outlined-button.mat-accent:before {
    border-color: mat.m2-get-color-from-palette($accent-palette);
    border-top-color: transparent;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
