@use '@angular/material' as mat;

@mixin elevation() {
  .topaz-elevation-z1 {
    @include mat.elevation(1);
  }

  .topaz-elevation-z2 {
    @include mat.elevation(6);
  }

  .topaz-elevation-z3 {
    @include mat.elevation(12);
  }
}
