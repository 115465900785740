@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-mdc-tab {
    &:hover {
      background-color: map.get(p.$topaz-blue, 50);
    }
    &:focus-visible {
      z-index: 1000;
      background-color: map.get(p.$topaz-blue, 50);
      @include o.topaz-focus();
    }
  }

  .mat-mdc-tab-list {
    border-bottom: 1px solid map.get(p.$topaz-blue, 600);
  }

  .mdc-tab__text-label {
    color: map.get(p.$topaz-neutral, 700) !important;
    .ng-fa-icon {
      padding-left: 4px;
      color: map.get(p.$topaz-neutral, 700) !important;
    }
  }

  .mdc-tab--active .mdc-tab__text-label {
    color: map.get(p.$topaz-blue, 600) !important;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .mdc-tab__text-label {
    font-weight: mat.m2-font-weight($typography-config, 'headline-1');
  }
}

@mixin general() {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
      gap: 0.5em;
      .svg-inline--fa {
        vertical-align: -2px;
      }
    }
  }

  .mdc-tab {
    min-width: 180px !important;
  }

  .mat-mdc-tab-label-container {
    padding: 6px 6px 0px 6px;
  }

  .mat-mdc-tab-link-container {
    padding: 6px 6px 0px 6px;
  }

  .mat-mdc-tab-body-wrapper {
    padding: 0px 6px;
    @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
      padding: 0px 0.25rem;
    }
  }

  .mat-mdc-tab.mdc-tab {
    border-radius: 5px 5px 0px 0px;
  }
  .mdc-tab__content {
    pointer-events: all !important;
  }
  .mat-mdc-tab-header {
    padding-bottom: 16px;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
