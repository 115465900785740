@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables-imports' as v;
@use '../utilities/layout' as l;
@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  .topaz-error-page-background {
    background: v.$ui-white;
    color: v.$ui-white;
    .topaz-error-page-primary-area {
      background: mat.m2-get-color-from-palette($primary-palette, 900);
    }
  }
}

@mixin typography($theme) {
  $config: mat.m2-get-typography-config($theme);

  .topaz-error-page-background {
    h1 {
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 6.125rem;
    }
  }
}

@mixin general($theme) {
  .topaz-error-page-background {
    $error-image-height: 35%;

    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 901;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h1 {
      margin: 1rem;
    }
    p {
      margin: 1rem 3.625rem 2rem;
    }
    .topaz-error-image-container {
      height: $error-image-height;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      &.horizontal-start {
        justify-content: flex-start;
      }
      &.horizontal-end {
        justify-content: flex-end;
      }
      &.overflow {
        overflow: hidden;
        align-items: normal;
      }
      .topaz-error-page-img {
        height: 100%;
        width: auto;
        @include l.margin-utils($mobile: true);
      }
    }

    .topaz-error-page-primary-area {
      height: calc(100% - $error-image-height);
      width: 100%;
    }
  }

  .mobile-only {
    display: none !important;
  }

  @media screen and (max-width: map.get(v.$topaz-breakpoints-max, mobile)) {
    .mobile-only {
      display: flex !important;
    }
    .mobile-hidden {
      display: none !important;
    }

    .topaz-error-page-background {
      $error-image-height: 40%;

      .topaz-error-button {
        width: 80%;
      }

      .topaz-error-image-container {
        height: $error-image-height;
        justify-content: center;
        align-items: flex-end;
        .topaz-error-page-img {
          &.desktop-mobile-img {
            height: auto !important;
            width: 100% !important;
          }

          @include l.margin-utils($mobile: true);
        }
      }

      .topaz-error-page-primary-area {
        height: calc(100% - $error-image-height);
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-color-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general($theme);
}
