@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .mat-mdc-radio-button {
    .mdc-radio__outer-circle {
      border-color: rgba(0, 0, 0, 0.6);
    }

    &.mat-mdc-radio-checked {
      .mdc-radio__outer-circle {
        border-color: mat.m2-get-color-from-palette(
          $primary-palette,
          600
        ) !important;
      }

      .mdc-radio__inner-circle {
        border-color: mat.m2-get-color-from-palette(
          $primary-palette,
          600
        ) !important;
        background-color: mat.m2-get-color-from-palette(
          $primary-palette,
          600
        ) !important;
      }
    }
    .mdc-radio {
      &:hover .mat-mdc-focus-indicator {
        @include o.topaz-hover-alpha();
      }

      // Remove ripple when tabbing out because of conflicts with opacity
      .mdc-radio__background::before {
        transition: none !important;
      }

      .mdc-radio__native-control:focus + .mdc-radio__background::before {
        @include o.topaz-focus();
        background-color: rgba(0, 0, 0, 0.06) !important;
        transition: none !important;
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .mdc-radio__background .mdc-radio__outer-circle {
    transform: scale(1.1);
    border-width: 1.5px;
  }

  .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__inner-circle {
    transform: scale(0.6) !important;
  }
}

@mixin general() {
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
