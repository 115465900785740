$topaz-border-radius: 0.5rem !important;
$topaz-border-color: #d8d8d8;

$topaz-breakpoints-min: (
  large: 1400px,
  medium: 1439px,
  small: 1239px,
  x-small: 904px,
  mobile: 599px
);

$topaz-breakpoints-max: (
  mobile: 599px,
  x-small: 600px,
  small: 905px,
  medium: 1240px,
  large: 1400px
);
