@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
}

@mixin typography($theme) {
  $config: mat.m2-get-typography-config($theme);
}

@mixin general($theme) {
  .topaz-toggle-section {
    .topaz-toggle-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  @include typography($theme);

  @include general($theme);
}
