@use 'sass:map';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../utilities/variables' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .label-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: map.get(p.$topaz-neutral, 700);
    padding-bottom: 4px;
  }

  .error-text {
    color: map.get(p.$topaz-red, 800);
    @include mat.m2-typography-level($typography-config, 'caption');
  }

  .hint-text {
    @include mat.m2-typography-level($typography-config, 'caption');
    color: map.get(p.$topaz-neutral, 700) !important;
  }

  input {
    @include mat.m2-typography-level($typography-config, 'body-1');
    color: map.get(p.$topaz-neutral, 700);

    &::placeholder {
      color: map.get(p.$topaz-neutral, 700) !important;
      @include mat.m2-typography-level($typography-config, 'body-1');
    }
  }
}

@mixin general() {
  a:not([class*='mat-']) {
    text-decoration: underline;
  }

  .single-file-uploader-form {
    width: 50%;
  }

  @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
    .single-file-uploader-form {
      width: 100%;
    }
  }

  .single-file-uploader-container {
    display: flex;
  }

  .form-input {
    display: flex;
    border: 1px solid;
    width: fit-content;
    display: inline-flex;
    align-items: center;

    fa-icon {
      &.error-icon svg {
        font-size: 18px;
        color: map.get(p.$topaz-red, 800) !important;
      }
      &.cancel-button svg {
        color: map.get(p.$topaz-neutral, 700) !important;
        cursor: pointer;
      }
    }
  }

  .hidden {
    display: none;
  }

  .hint-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .hint-error {
    display: flex;
    flex-direction: column;
  }

  .error-text {
    padding-top: 4px;
    margin-left: 20px;
  }

  .hint-text {
    padding-top: 4px;
    margin-right: 10px;
    margin-left: auto;
  }

  .form-input {
    height: 56px !important;
    width: 100%;
    border: 1px solid map.get(p.$topaz-neutral, 700) !important;
    border-radius: 6px 0 0 6px;
    box-sizing: border-box;

    & input {
      border: none;
      width: inherit;
      padding-left: 16px;
      text-overflow: ellipsis;
      &:focus {
        outline: none;
        border: none;
      }
    }

    &-error {
      @extend .form-input;
      & input {
        color: map.get(p.$topaz-red, 800) !important;
        padding-left: 0px;
        &:focus {
          outline: none;
          border: none;
        }
      }
      border: 2px solid map.get(p.$topaz-red, 800) !important;
      color: map.get(p.$topaz-red, 800) !important;
    }
  }

  .topaz-wrapper[dir='rtl'] {
    .browse-button {
      border-radius: 6px 0px 0px 6px !important;
    }

    .form-input {
      border-left: none;
      border-radius: 0px 6px 6px 0px;

      border-right-style: solid;
    }

    .hint-text {
      margin-right: auto;
      margin-left: 15px;
    }
  }

  .browse-button {
    &.mat-mdc-unelevated-button {
      height: 56px !important;
      border-radius: 0 6px 6px 0 !important;
    }
    &.mat-mdc-outlined-button {
      height: 56px !important;
      border-radius: 0 6px 6px 0 !important;
    }
    span {
      white-space: nowrap;
    }
    cursor: pointer;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }

  @include general();
}
