@use '@angular/material' as mat;
@use 'sass:map';
@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-bottom-bar {
    background-color: v.$ui-white;
    @include mat.elevation(6);
    border-top: 1px solid v.$ui-gray-2;
  }
}

@mixin typography($theme) {
}

@mixin general() {
  .topaz-bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: 0.75em 0.5em;
    box-sizing: border-box;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general();
}
