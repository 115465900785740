@use 'sass:map';
@use '@angular/material' as mat;
@use '../utilities/variables' as v;
@use '../theme/palettes' as p;
@use '../theme/typography';
@use '../utilities/outline' as o;
@use 'sass:color';

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .topaz-sidenav-overlay {
    background-color: color.scale(map.get(p.$topaz-neutral, 900), $alpha: -75%);
  }

  .topaz-sidenav.topaz-sidenav-mobile.topaz-sidenav-closed
    .topaz-sidenav-open-close-section {
    background-color: map.get(p.$topaz-neutral, 100) !important;
  }

  // Normal state
  .topaz-sidenav {
    border-inline-end: 1px solid map.get(p.$topaz-neutral, 300);

    .topaz-sidenav-container {
      background-color: map.get(p.$topaz-neutral, 50);
      .topaz-sidenav-open-close-section {
        border-block-end: 1px solid map.get(p.$topaz-neutral, 300);
      }

      .topaz-sidenav-list {
        overflow-y: auto;
        // height: calc(100vh - 88px);
        ul {
          a {
            color: map.get(p.$topaz-neutral, 900);
            outline: none;
            &:focus-visible {
              box-shadow: none !important;
            }
          }

          li {
            &:hover {
              background-color: var(
                --topaz-side-nav-background--active,
                mat.m2-get-color-from-palette($primary-palette, 50)
              );
            }

            &:has(> a:focus) {
              @include o.topaz-focus-inset();
            }

            span.topaz-sidenav-link {
              color: mat.m2-get-color-from-palette(p.$topaz-neutral, 900);
              span.topaz-sidenav-link-faicon {
                color: var(
                  --topaz-side-nav-text--active,
                  mat.m2-get-color-from-palette($primary-palette, 600)
                );
              }
            }

            &.topaz-sidenav-link-active {
              background-color: var(
                --topaz-side-nav-background--active,
                mat.m2-get-color-from-palette($primary-palette, 50)
              );
              box-sizing: border-box;
              border-inline-start: 4px solid
                var(
                  --topaz-side-nav-border--active,
                  mat.m2-get-color-from-palette($primary-palette, 600)
                );

              span.topaz-sidenav-link {
                color: var(
                  --topaz-side-nav-text--active,
                  mat.m2-get-color-from-palette($primary-palette, 600)
                );
                font-weight: 700;
                span.topaz-sidenav-link-faicon {
                  color: var(
                    --topaz-side-nav-text--active,
                    mat.m2-get-color-from-palette($primary-palette, 600)
                  );
                }
              }
            }
            &:not(.topaz-sidenav-link-active) {
              --topaz-side-nav-text--active: var(--topaz-neutral-700);
            }
          }
        }
      }
    }
  }

  .topaz-sidenav-focused {
    border-color: transparent;
    outline: 2px solid mat.m2-get-color-from-palette($primary-palette, 600);
    outline-offset: -1px;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .topaz-sidenav {
    .topaz-sidenav-container {
      .topaz-sidenav-list {
        ul {
          a {
            text-decoration: none;
          }

          li {
            &.topaz-sidenav-link-active {
              font-weight: bold;
            }

            span.topaz-sidenav-link {
              @include mat.m2-typography-level(
                mat.m2-get-typography-config($theme),
                'body-2'
              );

              display: table;
              span.topaz-sidenav-link-faicon {
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
        }

        .topaz-sidenav-group-name {
          @include typography.topaz-overline-style($typography-config);
          line-height: 25px;
          padding-top: 1rem;
        }
      }
    }
  }
}

@mixin general() {
  .topaz-wrapper[dir='rtl'] {
    .topaz-sidenav-open-close-section {
      right: 10px;
    }
  }

  .topaz-wrapper[dir='ltr'] {
    .topaz-sidenav-open-close-section {
      left: 10px;
    }
  }

  .topaz-sidenav-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    pointer-events: none;
  }

  .topaz-sidenav {
    height: 100%;

    .topaz-sidenav-container {
      height: calc(100vh - var(--topaz-sidenav-top-offset, 5.5rem));
      position: sticky;
      left: 0;
      top: var(--topaz-sidenav-top-offset, 5.5rem);
      z-index: 801;
      .topaz-sidenav-open-close-section {
        display: flex;
      }

      &.topaz-sidenav-open {
        background-color: var(--topaz-side-nav-background--open, white);
        .topaz-sidenav-open-close-section {
          justify-content: flex-end;
        }
      }
      &.topaz-sidenav-closed {
        background-color: var(--topaz-side-nav-background--open, white);
        .topaz-sidenav-open-close-section {
          margin: 0px 5px;
          justify-content: center;
        }
      }

      display: flex;
      flex-direction: column;

      .topaz-sidenav-list {
        overflow-y: auto;
        ul {
          margin: 0;
          padding: 0px;

          &:last-child {
            margin-bottom: 5px;
          }

          &:first-child {
            margin-top: 5px;
          }

          li {
            list-style-type: none;
            padding-inline-start: 1.5rem;
            padding-block: 0.75rem;
            cursor: pointer;

            &:hover {
              background-color: green;
            }

            &.topaz-sidenav-link-active {
              span.topaz-sidenav-link {
                position: relative;
                left: -4px;
              }
            }
          }
        }

        .topaz-sidenav-group-name {
          display: inline-block;
          padding-inline-start: 0.75rem;
          padding-block-start: 0.625rem;
        }
      }
    }

    @media screen and (max-width: map.get(v.$topaz-breakpoints-min, 'mobile')) {
      &.topaz-sidenav-mobile {
        top: 0px;
      }

      .topaz-side-nav-control {
        margin-top: 4em;
      }
    }
    // Mobile VIA breakpoint observer (sidenav.component.ts line 112)
    &.topaz-sidenav-mobile {
      position: fixed;
      z-index: 900;
      width: 100%;

      &.topaz-sidenav-closed {
        border: none;
        position: sticky;
        height: 0px;

        .topaz-sidenav-open-close-section {
          border-radius: 4px;
          border: none;
          position: fixed;
          top: 84px;
        }

        .topaz-sidenav-list {
          display: none;
        }
      }
      .topaz-sidenav-container.topaz-sidenav-closed {
        height: 0px !important;
      }

      .topaz-sidenav-container {
        top: 63px;
        height: calc(100vh - 63px);
      }
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
