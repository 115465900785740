@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../variables-imports' as v;

@mixin color($theme) {
  .local-selector-container {
    --topaz-dialog-background: var(--topaz-neutral-100);
  }
  .locale-dialog-container {
    background: var(--topaz-neutral-100);
    .locale-dialog-header-container {
      .locale-title {
        color: var(--topaz-neutral-900-black, #000);
      }
      .locale-language {
        color: var(--topaz-neutral-700, #666);
      }
      .active-locale-title {
        color: var(--topaz-neutral-900-black, #000);
      }
    }
    .other-locale-title {
      color: var(--topaz-neutral-700);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin general() {
  .mat-mdc-dialog-surface {
    --mdc-dialog-container-shape: 0.5rem;
    overflow-x: hidden;
  }

  .locale-dialog-container {
    --mat-dialog-actions-padding: 0 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    topaz-globe-logo-reverse {
      position: absolute;
      top: -1rem;
      left: 0;
    }
    .globe-image {
      position: absolute;
      right: -1.5rem;
      top: -1.5rem;

      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'small')) {
        right: 4rem;
        top: -2.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .locale-dialog-header-container {
      display: flex;
      flex-direction: column;
      mat-divider {
        width: 85%;
      }
      .locale-title {
        color: var(--topaz-neutral-900-black, #000);
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .locale-language {
        color: var(--topaz-neutral-700, #666);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1rem;
        letter-spacing: 0.08rem;
        text-transform: uppercase;
      }
      .active-locale-title {
        color: var(--topaz-neutral-900-black, #000);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'small')) {
      overflow: scroll !important;
    }
    mat-dialog-actions {
      margin-top: 2rem;
      gap: 1rem;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'small')) {
        margin-top: 0;
        padding-top: 0;
      }
    }
    .other-locale-title {
      color: var(--topaz-neutral-700);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem;
      letter-spacing: 0.08rem;
      text-transform: uppercase;
    }
    .topaz-locale-loader-close-btn {
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      @media screen and (max-width: map.get(v.$topaz-breakpoints-max, 'small')) {
        top: 0.5rem;
      }
    }

    .topaz-locale-loader-close-btn-rtl {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
    .locale-selector-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      min-height: 10rem;
      max-height: 20rem;
      width: auto;
      overflow: auto;
      --mat-dialog-content-padding: 0;
      @media screen and (min-width: map.get(v.$topaz-breakpoints-max, 'small')) {
        display: grid;
        grid-template-columns: 45% 45%;
        column-gap: 2rem;
        --mat-dialog-with-actions-content-padding: 0 1.25rem 0 0;
        max-height: 15rem;
        width: auto;
        @media screen and (max-height: map.get(v.$topaz-breakpoints-max, 'small')) {
          max-height: 10rem;
        }
      }
    }
    .locale-tile-container {
      @media screen and (max-height: map.get(v.$topaz-breakpoints-max, 'small')) {
        height: 2rem;
      }
    }
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
