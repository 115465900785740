@use 'sass:map';
@use '@angular/material' as mat;
@use '../variables-imports' as v;
@use '../theme/palettes' as p;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $alert-palette: map.get($theme, 'alert');

  .topaz-toast {
    background-color: mat.m2-get-color-from-palette(
      $primary-palette,
      '900-contrast'
    );
    border-width: 1px;
    border-style: solid;
    border-color: mat.m2-get-color-from-palette($primary-palette, 600);

    &-subcontent {
      color: map.get(p.$topaz-neutral, 700);
    }

    &.topaz-toast-color {
      &-success {
        border-color: mat.m2-get-color-from-palette($accent-palette, 700);
      }

      &-warn {
        border-color: mat.m2-get-color-from-palette($warn-palette, 800);
      }

      @if $alert-palette != null {
        &-alert {
          border-color: mat.m2-get-color-from-palette($alert-palette, 700);
        }
      }
    }
  }
}

@mixin typography($theme) {
}

@mixin general() {
  .topaz-toast {
    min-width: 80vw;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'icon content . close'
      '.    actions . .';
    justify-content: space-between;
    align-items: center;
    margin-block-end: 1.5rem;
    z-index: 900;
    padding: 1rem;
    border-radius: v.$topaz-border-radius;
    @include mat.elevation(9);
    column-gap: 1rem;
    box-sizing: border-box;

    @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
      min-width: 360px;
      width: 40vw;
      max-width: 680px;
      grid-template-areas: 'icon content actions close';
    }

    .t-warning {
      background-color: red;
    }

    .topaz-toast-content {
      grid-area: content;
      display: flex;
      align-items: center;
      gap: 0.5em;
      &.close-disabled {
        gap: 0;
      }
      &:focus {
        outline: none;
      }
    }

    .topaz-toast-action {
      grid-area: actions;
      margin-inline-start: -1rem;

      @media screen and (min-width: map.get(v.$topaz-breakpoints-min, mobile)) {
        &.topaz-toast-has-content {
          border-right: 1px solid v.$ui-gray-3;
          padding-right: 0.5em;
          margin-inline-start: 0;
        }
      }
    }

    .topaz-toast-end {
      grid-area: close;
      justify-self: end;
    }

    .topaz-toast-icon {
      height: 48px;
      grid-area: icon;
      img {
        height: 48px;
        width: 48px;
        border-radius: 4px;

        &.topaz-toast-custom-img-circle {
          border-radius: 100%;
        }
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }

  @include general();
}
