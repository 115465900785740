/// @depricated use mat-card
@use 'sass:map';
@use '@angular/material' as mat;

@use '../variables-imports' as v;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  .vue-panel {
    background-color: v.$ui-white;
    border-radius: v.$topaz-border-radius;
    margin: 2em 0;
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .vue-panel {
    font-family: mat.m2-font-family($theme);
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
