@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use '../theme/palettes' as p;
@use '../variables-imports' as v;
@use '../utilities/outline' as o;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $complementary-palette: map.get($theme, 'complementary');
  .topaz-tile-content {
    // Default color
    .tile-link-circle-icon {
      color: mat.m2-get-color-from-palette($primary-palette, 500);
    }
    // Prefer complementary if available
    @if $complementary-palette != null {
      .tile-link-circle-icon {
        color: mat.m2-get-color-from-palette($complementary-palette, 500);
      }
    }
    margin: 4px;
    &:focus {
      @include o.topaz-focus();
      border-radius: 10px;
    }

    .mdc-card {
      border: 1px solid map.get(p.$topaz-neutral, 400);
      background: map.get(p.$topaz-neutral, 50);

      .mat-mdc-card-title,
      .mat-mdc-card-subtitle,
      .tile-link-chevron-icon {
        color: map.get(p.$topaz-neutral, 700);
        padding-right: 1rem;
      }
      &:active {
        border: 2px solid mat.m2-get-color-from-palette($primary-palette, 400);
        background: v.$ui-white;
      }
      &:focus {
        border: 1px solid map.get(p.$topaz-neutral, 400);
        background: v.$ui-white;
      }
      &:hover {
        border: 1px solid map.get(p.$topaz-neutral, 400);
        background: mat.m2-get-color-from-palette($primary-palette, 50);
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);
      }

      .mat-mdc-card-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
      .mat-mdc-card-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
    &-disabled {
      border: 1px solid map.get(p.$topaz-neutral, 300);
      background: map.get(p.$topaz-neutral, 200) !important;
      .mat-mdc-card-title,
      .mat-mdc-card-subtitle {
        color: map.get(p.$topaz-neutral, 500) !important;
      }
      .mat-icon,
      .tile-link-circle-icon {
        color: map.get(p.$topaz-neutral, 500);
      }
    }

    &-active {
      border: 2px solid mat.m2-get-color-from-palette($primary-palette, 400) !important;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  .topaz-tile-content {
    .mdc-card {
      display: grid;
      grid-template-columns: 90% 10%;
      grid-gap: 1em;
      .mat-mdc-card-title {
        @include mat.m2-typography-level($typography-config, 'body-1');
        font-weight: 700;
      }

      .mat-mdc-card-subtitle {
        @include mat.m2-typography-level($typography-config, 'caption');
      }
    }
  }
}

@mixin general() {
  .topaz-tile-content {
    .tile-link-circle-icon {
      width: 1.375rem;
      height: 1.375rem;
      display: flex;
      justify-content: center;
      padding-inline-end: 0.5rem;
    }

    .mdc-card {
      display: flex;
      min-height: 75px;
      padding: 0.75rem 1rem;
      align-items: center;
      flex-shrink: 0;
      border-radius: 0.5rem;
      flex-direction: row;
      outline: none;

      .mat-mdc-card-content {
        padding: 0 !important;
      }
      .mat-mdc-card-content {
        width: 100%;
      }
      .mat-mdc-card-title {
        /**
        // Uncomment this code to enable text truncation
        text-overflow: ellipsis;
        display: inline-block;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-wrap: none;
         */

        max-width: 99%;
      }
    }
    .mat-mdc-card {
      padding: 12px 16px !important;
      margin: 0 !important;
    }
  }
  .topaz-tile-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

@mixin theme($theme) {
  @include general();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
